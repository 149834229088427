import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Switch,
  Space,
  Menu,
} from "antd";
import { Link } from "react-router-dom";
import "./RoleForm.css";
import axios from "axios";
import { url } from "../../constant/url";
import moment from "moment";
import _ from "underscore";
import { getRoles } from "@testing-library/dom";

const { Content } = Layout;
const { TextArea } = Input;
const { SubMenu } = Menu;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function RoleForm(props) {
  const [data, setData] = useState({
    name: "",
    description: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);

  useEffect(() => {
    getPermission();
  }, []);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleChangeSwitch = (name, value) => {
    setRolePermission({
      ...rolePermission,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    axios.post(
      `${url}/role`,
      {
        name: data.name,
        description: data.description,
        permissions: rolePermission,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/role");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      description: "",
      name: "",
    });
  };

  const getPermission = () => {
    setLoading(true);

    axios.get(`${url}/permission/list-permission-parent`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPermissions(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Role"
            extra={moment(new Date()).format("DD MMMM YYYY")}
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/role">Kembali</Link>{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add Role</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Name"
                  validateStatus={error && error.name ? "error" : false}
                  help={error && error.name ? error.name[0] : false}
                >
                  <Input
                    value={data.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Description"
                  validateStatus={error && error.description ? "error" : false}
                  help={
                    error && error.description ? error.description[0] : false
                  }
                >
                  <TextArea
                    value={data.description}
                    onChange={(e) =>
                      handleChange("description", e.target.value)
                    }
                  />
                </Form.Item>

                <Form.Item label="Permissions">
                  {_.chunk(permissions, 2).map((chunk, index) => {
                    return (
                      <div key={index}>
                        {chunk.map((permission, index) => {
                          return (
                            <Menu
                              key={index}
                              mode="inline"
                              className="menu-permission"
                            >
                              {permission.children === 0 ? (
                                <Menu.Item key={permission._id}>
                                  <Space>
                                    <Switch
                                      checked={
                                        rolePermission[permission._id]
                                          ? rolePermission[permission._id]
                                          : false
                                      }
                                      value={permission._id}
                                      onChange={(value) =>
                                        handleChangeSwitch(
                                          permission._id,
                                          value
                                        )
                                      }
                                    />
                                    <span>{permission.name}</span>
                                  </Space>
                                </Menu.Item>
                              ) : (
                                <SubMenu
                                  key={permission._id}
                                  title={
                                    <Space>
                                      <Switch
                                        checked={
                                          rolePermission[permission._id]
                                            ? rolePermission[permission._id]
                                            : false
                                        }
                                        value={permission._id}
                                        onChange={(value) =>
                                          handleChangeSwitch(
                                            permission._id,
                                            value
                                          )
                                        }
                                      />
                                      <span>{permission.name}</span>
                                    </Space>
                                  }
                                >
                                  {permission.children &&
                                    permission.children.map((child) => {
                                      return child.children === 0 ? (
                                        <Menu.Item key={child._id}>
                                          <Space>
                                            <Switch
                                              checked={
                                                rolePermission[child._id]
                                                  ? rolePermission[child._id]
                                                  : false
                                              }
                                              value={child._id}
                                              onChange={(value) =>
                                                handleChangeSwitch(
                                                  child._id,
                                                  value
                                                )
                                              }
                                            />
                                            <span>{child.name}</span>
                                          </Space>
                                        </Menu.Item>
                                      ) : (
                                        <SubMenu
                                          key={child._id}
                                          title={
                                            <Space>
                                              <Switch
                                                checked={
                                                  rolePermission[child._id]
                                                    ? rolePermission[child._id]
                                                    : false
                                                }
                                                value={child._id}
                                                onChange={(value) =>
                                                  handleChangeSwitch(
                                                    child._id,
                                                    value
                                                  )
                                                }
                                              />
                                              <span>{child.name}</span>
                                            </Space>
                                          }
                                        >
                                          {child.children &&
                                            child.children.map((grandchild) => {
                                              return (
                                                <Menu.Item key={grandchild._id}>
                                                  <Space>
                                                    <Switch
                                                      checked={
                                                        rolePermission[
                                                          grandchild._id
                                                        ]
                                                          ? rolePermission[
                                                              grandchild._id
                                                            ]
                                                          : false
                                                      }
                                                      value={grandchild._id}
                                                      onChange={(value) =>
                                                        handleChangeSwitch(
                                                          grandchild._id,
                                                          value
                                                        )
                                                      }
                                                    />
                                                    <span>
                                                      {grandchild.name}
                                                    </span>
                                                  </Space>
                                                </Menu.Item>
                                              );
                                            })}
                                        </SubMenu>
                                      );
                                    })}
                                </SubMenu>
                              )}
                            </Menu>
                          );
                        })}
                      </div>
                    );
                  })}
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default RoleForm;
