import {
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Card,
    Upload,
    Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import React, { useEffect, useState, Fragment } from 'react';
import {
    PlusOutlined,
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    ImportOutlined,
} from '@ant-design/icons';
import Nestable from 'react-nestable';
import './Ng.css';
import Can from '../../components/Can';
import axios from 'axios';
import { url } from '../../constant/url';
import _ from 'underscore';
import fileDownload from 'js-file-download';

function Ng(history, match) {
    const [isModalVisibleAdd, setIsModalVisibleAdd] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [ngs, setNgs] = useState([]);
    const [data, setData] = useState({
        name: '',
    });
    const [keyword, setKeyword] = useState('');
    const [parents, setParents] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });

    const { Option } = Select;

    useEffect(() => {
        getData();
    }, [keyword]);

    const handleReset = () => {
        setData({
            name: '',
        });
    };

    const getData = () => {
        axios
            .get(`${url}/ng`, {
                params: {
                    keyword,
                    columns: ['name'],
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setNgs(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const handleChange = (name, value) => {
        setErrors({
            ...errors,
            [name]: '',
        });

        setData({
            ...data,
            [name]: value,
        });
    };

    const handleOk = async (e) => {
        e.preventDefault();

        if (data.id) {
            setLoading(true);
            handleUpdate('all', data, data);
            setIsModalVisibleAdd(false);
            setLoading(false);
            handleReset();
        } else {
            axios
                .post(
                    `${url}/ng`,
                    {
                        name: data.name,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    },
                )
                .then((res) => {
                    message.success(res.data.message);
                    handleReset();
                    getData();
                    setIsModalVisibleAdd(false);
                })
                .catch((err) => {
                    if (err.response) {
                        setErrors(err.response.data.errorDetails);
                        message.error(err.response.data.message);
                    }
                    setLoading(false);
                });
        }
    };

    const handleSaveMany = (bulk) => {
        axios
            .post(
                `${url}/ng/bulk`,
                {
                    data: bulk,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                },
            )
            .then((res) => {})
            .catch((err) => {
                if (err.response) {
                    setErrors(err.response.data.errorDetails);
                    message.error(err.response.data.message);
                }
            });
    };

    const handleCancelAdd = () => {
        setIsModalVisibleAdd(false);
        handleReset();
    };

    const handleRemove = (item) => {
        Modal.confirm({
            title: 'Are you sure ?',
            content: (
                <div>
                    <p>Deleted data cannot be restored</p>
                </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return;
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(item);
            },
        });
    };

    const checkRecursiveDelete = (children, item) => {
        const findMenuChild = children
            .map((menu) => {
                const findChildren = menu.children.find(
                    (x) => x.id === item.id,
                );
                if (findChildren) {
                    return { ...findChildren, parent_id: menu.id };
                } else {
                    if (menu.children.length > 0) {
                        return checkRecursiveDelete(menu.children, item);
                    }
                }
            })
            .filter((x) => x);

        const findParent = children.find(
            (x) => x.id === findMenuChild[0].parent_id,
        );
        if (findParent) {
            findParent.children = findParent.children.filter(
                (x) => x.id !== item.id,
            );
        }
        return children;
    };

    const confirmDelete = (item) => {
        let newNgs = [...ngs];
        const findMenu = newNgs.find((x) => x.id === item.id);
        if (findMenu) {
            newNgs = newNgs.filter((x) => x.id !== item.id);
        } else {
            const findMenuChild = newNgs
                .map((menu) => {
                    const findChildren = menu.children.find(
                        (x) => x.id === item.id,
                    );
                    if (findChildren) {
                        return { ...findChildren, parent_id: menu.id };
                    } else {
                        if (menu.children.length > 0) {
                            return checkRecursiveDelete(menu.children, item);
                        }
                    }
                })
                .filter((x) => x);

            const findParent = newNgs.find(
                (x) => x.id === findMenuChild[0].parent_id,
            );
            if (findParent) {
                findParent.children = findParent.children.filter(
                    (x) => x.id !== item.id,
                );
            }
        }
        handleSaveMany(newNgs);
        setNgs(newNgs);
    };

    const handleEdit = (item) => {
        setData({
            ...data,
            id: item.id,
            name: item.name,
        });

        setIsModalVisibleAdd(true);
    };

    const checkRecursiveMenu = (field, value, children, item) => {
        const findMenuChild = children
            .map((child) => {
                const findChildren = child.children.find(
                    (x) => x.id === item.id,
                );
                if (findChildren) {
                    return { ...findChildren, parent_id: child.id };
                } else {
                    if (child.children.length > 0) {
                        return checkRecursiveMenu(
                            field,
                            value,
                            child.children,
                            item,
                        );
                    }
                }
            })
            .filter((x) => x);

        const findParent = children.find(
            (x) => x.id === findMenuChild[0].parent_id,
        );
        if (findParent) {
            const newChildren = [...findParent.children];
            const findChildren = newChildren.find(
                (x) => x.id === findMenuChild[0].id,
            );
            if (field === 'all') {
                findChildren.name = value.name;
            } else {
                findChildren[field] = value;
            }
        }
        return children;
    };

    const handleUpdate = (field, value, item) => {
        const newNgs = [...ngs];
        const findNg = newNgs.find((x) => x.id === item.id);
        if (findNg) {
            if (field === 'all') {
                findNg.name = value.name;
            } else {
                findNg[field] = value;
            }
        } else {
            const findMenuChild = newNgs
                .map((menu) => {
                    const findChildren = menu.children.find(
                        (x) => x.id === item.id,
                    );
                    if (findChildren) {
                        return { ...findChildren, parent_id: menu.id };
                    } else {
                        if (menu.children.length > 0) {
                            return checkRecursiveMenu(
                                field,
                                value,
                                menu.children,
                                item,
                                menu.id,
                            );
                        }
                    }
                })
                .filter((x) => x);

            const findParent = newNgs.find(
                (x) => x.id === findMenuChild[0].parent_id,
            );
            if (findParent) {
                const newChildren = [...findParent.children];
                const findChildren = newChildren.find(
                    (x) => x.id === findMenuChild[0].id,
                );
                if (field === 'all') {
                    findChildren.name = value.name;
                } else {
                    findChildren[field] = value;
                }
            }
        }
        handleSaveMany(newNgs);
        setNgs(newNgs);
    };

    const downloadTemplate = () => {
        axios
            .get(`${url}/ng/download`, {
                params: {
                    keyword,
                    columns: ['name'],
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, 'export_ng.xlsx');
            });
    };

    const handleBeforeUpload = (files) => {
        setData({
            ...data,
            file: files,
        });

        return false;
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setData({
            ...data,
            file: null,
        });
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onSaveData = () => {
        setLoading(true);

        let fd = new FormData();

        fd.append('file', data.file);

        axios
            .post(`${url}/ng/import`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
                closeModal();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 422) {
                        setErrors(err.response.data.errors);
                    }
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                } else {
                    message.error('Backend not yet Started');
                }
                setLoading(false);
            });
    };

    const getParent = async (keyword) => {
        await axios
            .get(`${url}/parent/list`, {
                params: {
                    keyword,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setParents(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const handleChangeSelect = (name, value) => {
        setErrors(null);
        if (value) {
            setData({
                ...data,
                [`${name}_id`]: value.value,
                [`${name}_number`]: value.label,
            });
        } else {
            setData({
                ...data,
                [`${name}_id`]: null,
                [`${name}_name`]: null,
            });
        }
    };

    const renderItem = ({ item }) => {
        return (
            <div className="menu-wrapper">
                <div>{item.name}</div>
                <div>
                    <Tooltip
                        placement="top"
                        title={item.is_active ? 'Disable' : 'Enable'}
                    >
                        <Button
                            className="mr-5"
                            type="ghost"
                            onClick={() =>
                                handleUpdate('is_active', !item.is_active, item)
                            }
                        ></Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Edit">
                        <Button
                            className="mr-5"
                            type="ghost"
                            onClick={() => handleEdit(item)}
                        >
                            <i className="mdi mdi-pencil" />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Remove">
                        <Button type="ghost" onClick={() => handleRemove(item)}>
                            <i className="mdi mdi-close" />
                        </Button>
                    </Tooltip>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Modal
                okText={data.id ? 'Save Changes' : 'Save'}
                okButtonProps={{ loading }}
                cancelButtonProps={{ hidden: loading }}
                title={data.id ? 'Edit NG' : 'Add New NG'}
                visible={isModalVisibleAdd}
                onOk={(values) => handleOk(values)}
                onCancel={handleCancelAdd}
            >
                <Form layout="vertical">
                    <Form.Item label="Name" required>
                        <Input
                            placeholder="Name"
                            value={data.name}
                            onChange={(e) =>
                                handleChange('name', e.target.value)
                            }
                        />
                    </Form.Item>
                    <div className="form-group">
                        Parent <span className="error-text">*</span>
                        <Select
                            style={{ width: 475 }}
                            labelInValue
                            onChange={(value) =>
                                handleChangeSelect('parent', value, data._id)
                            }
                            onFocus={() => getParent('')}
                            showSearch
                            onSearch={(value) => getParent(value)}
                            filterOption={false}
                            value={{
                                key: data.parent_id,
                                label: data.parent_name,
                            }}
                        >
                            {parents &&
                                parents.map((parent) => {
                                    return (
                                        <Option
                                            value={parent._id}
                                            key={parent._id}
                                        >
                                            {parent.parent_name}
                                        </Option>
                                    );
                                })}
                        </Select>
                        {errors && errors.parent_name && (
                            <span className="error-text">
                                {errors.parent_name[0]}
                            </span>
                        )}
                    </div>
                </Form>
            </Modal>

            <ul className="breadcumb">
                <li className="active">NG</li>
            </ul>
            <div className="content">
                <Row justify="space-between">
                    <Col>
                        <Can accessTo="Create NG">
                            <Button
                                type="primary"
                                style={{
                                    backgroundColor: '#1b2086',
                                    borderColor: '#1b2086',
                                }}
                                onClick={() => setIsModalVisibleAdd(true)}
                            >
                                {/* <i className="mdi mdi-plus mr-5" /> */}
                                <PlusOutlined />
                                Add
                            </Button>
                        </Can>

                        <Modal
                            title="Import NG"
                            visible={isModalVisible}
                            footer={null}
                            onCancel={closeModal}
                            onCancel={handleCancel}
                        >
                            <Card className="body-data">
                                <div name="control-hooks">
                                    <div name="file" label="File">
                                        <Fragment>
                                            <Upload
                                                onRemove={handleRemove}
                                                beforeUpload={
                                                    handleBeforeUpload
                                                }
                                                fileList={[]}
                                            >
                                                <Button
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    Choose File{' '}
                                                    <ExportOutlined />
                                                </Button>
                                            </Upload>
                                            {data.file && (
                                                <React.Fragment
                                                    children={[
                                                        <Button
                                                            onClick={
                                                                handleRemove
                                                            }
                                                            type="danger"
                                                            ghost
                                                        >
                                                            <DeleteOutlined />
                                                        </Button>,
                                                    ]}
                                                >
                                                    {data.file.name
                                                        ? data.file.name
                                                        : data.file}
                                                </React.Fragment>
                                            )}
                                        </Fragment>
                                    </div>
                                    <div>
                                        <Button
                                            loading={loading}
                                            onClick={onSaveData}
                                            type="primary"
                                            htmlType="submit"
                                            className="mr-button"
                                            style={{
                                                marginRight: 10,
                                                backgroundColor: '#1b2086',
                                                borderColor: '#1b2086',
                                            }}
                                        >
                                            Upload
                                        </Button>
                                        <Button
                                            htmlType="reset"
                                            onClick={handleRemove}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Modal>

                        <Can accessTo="Import NG">
                            <Button
                                onClick={showModal}
                                type="primary"
                                style={{
                                    backgroundColor: '#1b2086',
                                    borderColor: '#1b2086',
                                    marginLeft: 10,
                                }}
                            >
                                <ImportOutlined />
                                Import
                            </Button>
                        </Can>

                        <Can accessTo="Export NG">
                            <Button
                                onClick={downloadTemplate}
                                type="primary"
                                style={{
                                    backgroundColor: '#1b2086',
                                    borderColor: '#1b2086',
                                    marginLeft: 10,
                                }}
                            >
                                <ExportOutlined />
                                Export
                            </Button>
                        </Can>
                    </Col>
                    <Col>
                        <Input.Search
                            allowClear="true"
                            onSearch={(value) => setKeyword(value)}
                            placeholder="Search..."
                        />
                    </Col>
                </Row>
                <Row className="mt-40 mb-20">
                    <Nestable
                        className="menu-list mb-20"
                        onChange={(data) =>
                            handleSaveMany(
                                data.map((data, index) => {
                                    return { ...data, order_number: index };
                                }),
                            )
                        }
                        items={_.sortBy(ngs, (x) => x.order_number)}
                        renderItem={renderItem}
                    />
                </Row>
            </div>
        </div>
    );
}

export default Ng;
