import {
    Card,
    Col,
    Dropdown,
    DatePicker,
    Input,
    Menu,
    Row,
    Space,
    Table,
    message,
} from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Axios from '../../../config/axios';
import moment from 'moment';
import GenerateLabelHistory from '../GenerateLabelInternal/components/GenerateLabelHistory';
import ConfirmationPrintOutBulk from './components/ConfirmationPrintOutBulk';
import ConfirmationPrintOut from './components/ConfirmationPrintOut';
import FilterPrintOut from './components/FilterPrintOut';
import ExportGenerateSPCard from '../GenerateSPCARD/components/ExportGenerateSPCard';
import './Custom.css';
import Can from '../../../components/Can';
function PrintOutLabel() {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState([
        {
            order: 'descend',
            field: 'diameter',
        },
        {
            order: 'descend',
            field: 'panjang',
        },
        {
            order: 'ascend',
            field: 'material',
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState(null);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [isVisiblePrintBulk, setIsVisiblePrintBulk] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const [isExportVisible, setIsExportVisible] = useState(false);

    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('day').toISOString(),
        endDate: moment().endOf('day').toISOString(),
    });
    const [dateRangeValue, setDateRangeValue] = useState([moment(), moment()]);

    // individual print
    const [isPrintVisible, setIsPrintVisible] = useState(false);
    const [printId, setPrintId] = useState(null);
    const [printRemaining, setPrintRemaining] = useState(null);

    useEffect(() => {
        if (dateRange.startDate && dateRange.endDate) {
            getData();
        }

        if (dateRange.startDate === null && dateRange.endDate === null) {
            getData();
        }
    }, [keyword, page, perpage, sort, dateRange]);

    const handleDateRange = (dates) => {
        if (dates && dates.length === 2) {
            setDateRange({
                startDate: dates[0]
                    ? dates[0].startOf('day').toISOString()
                    : null,
                endDate: dates[1] ? dates[1].endOf('day').toISOString() : null,
            });
        } else {
            setDateRange({
                startDate: null,
                endDate: null,
            });
        }

        setDateRangeValue(dates);
    };

    const getData = (filters = null) => {
        const formattedStartDate = dateRange.startDate
            ? moment(dateRange.startDate).format('YYYY-MM-DD')
            : null;
        const formattedEndDate = dateRange.endDate
            ? moment(dateRange.endDate).format('YYYY-MM-DD')
            : null;

        console.log(formattedStartDate, formattedEndDate);
        Axios.get('/printout-label', {
            params: {
                keyword,
                filters,
                perpage: perpage,
                page,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                sort,
            },
        })
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        if (!Array.isArray(sorter)) {
            setSort([sorter]);
        } else {
            setSort(sorter);
        }
    };

    const onSelectRowChange = (newSelectedRowKeys) => {
        let selected = data.filter((d) => newSelectedRowKeys.includes(d._id));
        setSelectedRows(selected);
    };

    const handlePrint = (id, printRem) => {
        setIsPrintVisible(true);
        setPrintId(id);
        setPrintRemaining(printRem);
    };

    const onFinishPrintBulk = () => {
        getData();
        setSelectedRows([]);
    };

    const hasSelected = selectedRows.length > 0;

    const rowClassName = (record) => {
        return record.print_remaining <= 0 ? 'row-generate' : '';
    };

    const columns = [
        {
            title: 'Code',
            dataIndex: 'qrcode',
            key: 'qrcode',
            sorter: {
                multiple: 4,
            },
        },
        {
            title: 'Generate Time',
            dataIndex: 'generate_time',
            key: 'generate_time',
            sorter: {
                multiple: 4,
            },
            render: (_, data) => {
                return moment(data.generate_time).format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            key: 'part_number',
            sorter: {
                multiple: 4,
            },
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            sorter: {
                multiple: 5,
            },
        },
        {
            title: 'Departemen',
            dataIndex: 'department',
            key: 'department',
            sorter: {
                multiple: 8,
            },
        },
        {
            title: 'Main Proccess Name',
            dataIndex: 'main_process',
            key: 'main_process',
            sorter: {
                multiple: 8,
            },
        },

        {
            title: 'Line Proccess Name',
            dataIndex: 'line_process',
            key: 'line_process',
            sorter: true,
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
            sorter: true,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            sorter: true,
        },
        //   {
        //     title: "Inspector",
        //     dataIndex: "inspector",
        //     key: "inspector",
        //     sorter: true,
        // },
        //   {
        //     title: "Prod Date",
        //     dataIndex: "prod_date",
        //     key: "prod_date",
        //     sorter: true,
        // },
        {
            title: 'Section',
            dataIndex: 'section',
            key: 'section',
            sorter: true,
        },
        //     {
        //       title: "Quality",
        //       dataIndex: "quality",
        //       key: "quality",
        //       sorter: true,
        //   },
        //   {
        //     title: "Shift",
        //     dataIndex: "shift",
        //     key: "shift",
        //     sorter: true,
        // },
        {
            title: 'Qty S.P. Card',
            dataIndex: 'qty_spcard',
            key: 'qty_spcard',
            sorter: {
                multiple: 9,
            },
        },
        {
            title: 'Qty Current',
            dataIndex: 'qty_current',
            key: 'qty_current',
            sorter: true,
        },
        {
            title: 'Qty Ng',
            dataIndex: 'qty_ng',
            key: 'qty_ng',
            sorter: true,
        },
        {
            title: 'Qty Internal',
            dataIndex: 'qty_internal',
            key: 'qty_internal',
            sorter: true,
        },
        {
            title: 'Print Total',
            dataIndex: 'print_total',
            key: 'print_total',
            sorter: true,
        },
        {
            title: 'Print Remaining',
            dataIndex: 'print_remaining',
            key: 'print_remaining',
            sorter: true,
        },

        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            sorter: false,
            fixed: 'right',
            width: 50,
            render: (_, record) => {
                if (record.print_remaining > 0) {
                    return (
                        <>
                            <Dropdown
                                className="pointer"
                                overlay={
                                    <Menu>
                                        <Menu.Item
                                            key="1"
                                            onClick={() =>
                                                handlePrint(
                                                    record._id,
                                                    record.print_remaining,
                                                )
                                            }
                                        >
                                            <PrinterOutlined /> Print
                                        </Menu.Item>
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <span
                                    className="pointer"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <i className="mdi mdi-dots-vertical" />
                                </span>
                            </Dropdown>
                        </>
                    );
                }
            },
        },
    ];

    return (
        <div>
            <ul className="breadcumb">
                <li className="active">Print Out S.P.CARD DEB</li>
            </ul>
            <Card>
                <Row justify="space-between" gutter={[16, 16]}>
                    <Col>
                        <Space>
                            <ConfirmationPrintOutBulk
                                isVisible={isVisiblePrintBulk}
                                setIsVisible={setIsVisiblePrintBulk}
                                onFinish={onFinishPrintBulk}
                                selected={selectedRows}
                                disabled={!hasSelected}
                            />

                            <Can accessTo="Export Print Out Label">
                                <ExportGenerateSPCard
                                    isVisible={isExportVisible}
                                    setIsVisible={setIsExportVisible}
                                />
                            </Can>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Can accessTo="Filter Print Out Label">
                                <DatePicker.RangePicker
                                    style={{ width: '100%' }}
                                    onCalendarChange={handleDateRange}
                                    value={dateRangeValue}
                                />
                            </Can>
                            <FilterPrintOut onFinish={getData} />
                            <Input.Search
                                allowClear="true"
                                onSearch={(value) => {
                                    setPage(1);
                                    setKeyword(value);
                                }}
                                placeholder="Search..."
                                inputStyle={{
                                    whiteSpace: 'nowrap',
                                    overflowX: 'auto',
                                }}
                                style={{ width: '30rem' }}
                            />
                        </Space>
                    </Col>
                </Row>
                <ConfirmationPrintOut
                    id={printId}
                    isVisible={isPrintVisible}
                    setIsVisible={setIsPrintVisible}
                    onFinish={getData}
                    printRemaining={printRemaining}
                />
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            scroll={{ x: 4000 }}
                            rowSelection={{
                                selectedRows,
                                onChange: onSelectRowChange,
                                getCheckboxProps: (record) => ({
                                    disabled: record.print_remaining <= 0,
                                    className:
                                        record.print_remaining <= 0
                                            ? 'row-generate'
                                            : '',
                                }),
                            }}
                            columns={columns}
                            dataSource={data}
                            pagination={{
                                current: page,
                                total,
                                showSizeChanger: true,
                                pageSizeOptions: [
                                    '10',
                                    '20',
                                    '50',
                                    '100',
                                    total.toString(),
                                ],
                            }}
                            loading={loading}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                            expandable={{
                                expandedRowKeys: expandedRowKeys,
                                onExpand: handleExpand,
                                expandedRowRender: (record) => (
                                    <GenerateLabelHistory
                                        key={`history-${record._id}`}
                                        id={record._id}
                                        rows={expandedRowKeys}
                                    />
                                ),
                            }}
                            rowClassName={rowClassName}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default PrintOutLabel;
