import React from "react";
import {
  Avatar,
  Button,
  Col,
  Form,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Table,
  Upload,
  DatePicker,
  Card,
  Tag,
} from "antd";
const { TextArea } = Input;
function ModalRev({
  title,
  visible,
  onCancel,
  bodyTitle,
  data,
  onHandleSave,
  newRevNo,
  errors,
  dataEdit,
  onHandleChange,
  dateRevision,
  onLoading,
  onHandleReset,
}) {
  return (
    <Modal title={title} visible={visible} onCancel={onCancel} footer={null}>
      <Card>
        <div>
        {bodyTitle}    
        <div className="content-body" style={{ marginTop: "20px" }}>
        <Row gutter={20}>
          <Col md={8} xs={24} style>
            Part Number
          </Col>
          <Col md={8} xs={24}>
            Part Name
          </Col>
          <Col md={8} xs={24}>
            Model
          </Col>
        </Row>
        <Row gutter={20}>
          <Col md={8} xs={24}>
            {dataEdit?.part_number ? dataEdit?.part_number : dataEdit?.tbm_spcard_header.part_number}
          </Col>
          <Col md={8} xs={24}>
            {dataEdit?.part_name ? dataEdit?.part_name : dataEdit?.tbm_spcard_header.part_name}
          </Col>
          <Col md={8} xs={24}>
            {dataEdit?.part_name ? dataEdit?.part_name : dataEdit?.tbm_spcard_header.model}
          </Col>
        </Row>
        </div>
        <div style={{ marginTop: "20px" }}>

          <Form onFinish={onHandleSave} autoComplete="off">
    
            <Row gutter={20}>
              <Col md={12} xs={24}>
                <div className="form-group">
                  <label className="form-label">
                    Rev No <span className="error-text">*</span>
                  </label>
                  <Form.Item
      required
      validateStatus={
        errors && errors.rev_no ? "errors" : false
      }
      help={
        errors && errors.rev_no ? errors.rev_no[0] : false
      }
    >
      <Input value={data?.rev_no}
                          onChange={(e) =>
                            onHandleChange("rev_no", e.target.value)
                          }/>
    </Form.Item>

                  {errors && errors.rev_no && (
                    <span className="error-text">{errors.rev_no[0]}</span>
                  )}
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="form-group">
                  <label className="form-label">
                    Rev Date <span className="error-text">*</span>
                  </label>
                  {/* <DatePicker defaultValue={moment('2015/01/01', dateFormat)} format={dateFormat} /> */}
                  {/* <DatePicker onChange={(value) => handleChange("date_revision", value)} 
                defaultValue={moment()} format={dateFormat}/> */}

                  <Input
                    type="date"
                    value={data?.date_revision}
                    className={errors && errors.date_revision ? "is-error" : ""}
                    onChange={(e) =>
                      onHandleChange("date_revision", e.target.value)
                    }
                  />
                  {errors && errors.date_revision && (
                    <span className="error-text">
                      {errors.date_revision[0]}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
            <Row gutter={50}>
              <Col md={24} xs={36}>
                <div className="form-group">
                  <label className="form-label">
                    Rev Content <span className="error-text">*</span>
                  </label>
                  <TextArea
                  value={data?.rev_content}
                    rows={4}
                    className={errors && errors.rev_content ? "is-error" : ""}
                    onChange={(e) =>
                      onHandleChange("rev_content", e.target.value)
                    }
                    
                  />
                  
                  {errors && errors.rev_content && (
                    <span className="error-text">{errors.rev_content[0]}</span>
                  )}
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <Button
                type="primary"
                onClick={onHandleSave}
                loading={onLoading}
                style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
              >
                {/* <i className="mdi mdi-content-save mr-5" />  */}
                Submit
              </Button>

              <Button
                htmlType="reset"
                type="primary"
                onClick={onHandleReset}
                loading={onLoading}
                style={{
                  backgroundColor: "#1b2086",
                  marginLeft: 10,
                  borderColor: "#1b2086",
                }}
              >
                {/* <i className="mdi mdi-content-save mr-5" /> */}
                Reset
              </Button>
            </div>
          </Form>
                  </div>
        </div>
      </Card>
    </Modal>
  );
}

export default ModalRev;
