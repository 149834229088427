import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Table,
  Tag,
  message,
} from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Axios from '../../../../config/axios';

function GenerateLabelHistory({ id, rows }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(5);
    const [keyword, setKeyword] = useState('');
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });
    const [loading, setLoading] = useState(false);

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    useEffect(() => {
        getDetails();
        return () => {
            setData([]);
        };
    }, [page, perpage, sort, keyword, rows]);

    const getDetails = () => {
        setLoading(true);
        Axios.get(`/generate-label/${id}/history`, {
            params: {
                keyword,
                perpage: perpage,
                page,
                sort: sort.sort,
                order: sort.order,
                columns: ['qrcode', 'generated_by'],
            },
        })
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePrint = (id) => {
        setLoading(true);
        Axios.get(`/generate-label/history/${id}/print`, {
            responseType: 'blob',
        })
            .then(({ data }) => {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                getDetails();
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    message.error('Server error');
                } else {
                    if (error.response) {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const errorMessage = JSON.parse(reader.result);
                            message.error(errorMessage.message);
                        };
                        reader.readAsText(error.response.data);
                    } else {
                        console.error('Error:', error.message.message);
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    const columns = [
        {
            title: 'Qrcode',
            dataIndex: 'qrcode',
            key: 'qrcode',
            sorter: true,
        },
        {
            title: 'Qty',
            dataIndex: 'qty_current',
            key: 'qty_current',
            sorter: true,
        },
        {
            title: 'Generate By',
            dataIndex: 'generated_by',
            key: 'generated_by',
        },
        {
            title: 'Printed',
            dataIndex: 'printed',
            key: 'printed',
            render: (_, record) =>
                record.printed && <Tag color="green">Printed</Tag>,
        },
        {
            title: 'Print Time',
            dataIndex: 'print_time',
            key: 'print_time',
        },
        {
            title: 'Print By',
            dataIndex: 'print_by',
            key: 'print_by',
        },
        {
            title: 'Reprint Status',
            dataIndex: 'total_reprint',
            key: 'total_reprint',
            render: (_, record) =>
                record.total_reprint > 0 && (
                    <Tag color="orange">Reprint {record.total_reprint}x</Tag>
                ),
        },
        {
            title: 'Reprint By',
            dataIndex: 'reprint_by',
            key: 'reprint_by',
        },
        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            sorter: false,
            fixed: 'right',
            width: 50,
            render: (_, record) => {
                return (
                    <>
                        <Dropdown
                            className="pointer"
                            overlay={
                                <Menu>
                                    {/* {record.printed && ( */}
                                        <Menu.Item
                                            key="1"
                                            onClick={() =>
                                                handlePrint(record._id)
                                            }
                                        >
                                            <PrinterOutlined /> Re-Print
                                        </Menu.Item>
                                    {/* )} */}
                                </Menu>
                            }
                            trigger={['click']}
                        >
                            <span
                                className="pointer"
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="mdi mdi-dots-vertical" />
                            </span>
                        </Dropdown>
                    </>
                );
            },
        },
    ];
    return (
        <Card>
            <Row justify="end">
                <Col>
                    <Input.Search
                        allowClear="true"
                        onSearch={(value) => setKeyword(value)}
                        placeholder="Search..."
                    />
                </Col>
            </Row>
            <Row className="mt-40">
                <Col xs={24}>
                    <Table
                        scroll={{ x: true }}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            total,
                            pageSize: perpage,
                            showSizeChanger: true,
                            pageSizeOptions: [5, 10, 20, 50],
                        }}
                        loading={loading}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                    />
                </Col>
            </Row>
        </Card>
    );
}

export default GenerateLabelHistory;
