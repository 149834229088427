import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import "./NotFound.css";

function NotFound() {
  return (
    <div>
      <h1 className="header">404</h1>
      <h2 className="subheader">Error Not Found</h2>
      <Link to="/dashboard">
        <Button type="secondary">Back to Home</Button>
      </Link>
    </div>
  );
}

export default NotFound;
