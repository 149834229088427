import {
    Button,
    Col,
    DatePicker,
    Form,
    InputNumber,
    Row,
    Select,
    Spin,
    Upload,
    message,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import Axios from '../../../config/axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const { Option } = Select;

function AddGenerateSPCARD(props) {
    const [part, setPart] = useState(null);
    const [production_date, setProductionDate] = useState(null);
    const [qty_planning, setQtyPlanning] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const getSPCard = async (keyword = null) => {
        try {
            const response = await Axios.get('/master-spcard/list/data', {
                params: { keyword, revision: 1 },
            });
            const final = response.data.map((d) => ({
                value: d.id,
                label: `${d.part_number} - ${d.part_name}`,
            }));
            return final;
        } catch (error) {
            console.error('Error fetching SPCards:', error);
            throw error;
        }
    };

    const handleChangeDate = (value) => {
        setProductionDate(value);
    };

    const submit = () => {
        setLoading(true);
        var fd = new FormData();
        fd.append('spcard', part);
        fd.append('production_date', production_date);
        fd.append('qty_planning', qty_planning);
        Axios.post('/generate-spcard', fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(({ data }) => {
                message.success(data.message);
                props.history.push('/generate-spcard');
            })
            .catch((error) => {
                if (error.response.data.errors) {
                    setErrors(error.response.data.errors);
                }
                message.error(error.response.data.message);
            })
            .finally(() => setLoading(false));
    };

    return (
        <div>
            <ul className="breadcumb">
                <li>Generate S.P.CARD</li>
                <li className="active">Upload Data Generate S.P.CARD</li>
            </ul>
            <Content className="content">
                <Form layout="vertical">
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="Part Number"
                                name="part_id"
                                required
                                validateStatus={
                                    errors.spcard ? 'error' : ''
                                }
                                help={errors.spcard?.join(', ')}
                            >
                                <SearchInput
                                    placeholder="Type to search"
                                    fetch={getSPCard}
                                    onChange={(v) => setPart(v)}
                                    value={part}
                                />
                            </Form.Item>
                            <Form.Item label="Tanggal Produksi" required>
                                <DatePicker
                                    onChange={handleChangeDate}
                                    style={{ width: '100%' }}
                                    value={production_date}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Qty Planning"
                                required
                                validateStatus={
                                    errors.qty_planning ? 'error' : ''
                                }
                                help={errors.qty_planning?.join(', ')}
                            >
                                <InputNumber
                                    min={0}
                                    onChange={(v) => setQtyPlanning(v)}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button
                        type="primary"
                        icon={loading && <LoadingOutlined />}
                        disabled={loading}
                        onClick={() => submit()}
                    >
                        Submit
                    </Button>
                    <Link to="/generate-spcard">
                        <Button>Cancel</Button>
                    </Link>
                </Form>
            </Content>
        </div>
    );
}

export default AddGenerateSPCARD;

const SearchInput = (props) => {
    const [data, setData] = useState([]);
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        props.fetch().then((response) => setData(response));
    }, []);

    const handleSearch = (newValue) => {
        setValue(newValue);
        props.fetch(newValue).then((response) => setData(response));
    };

    return (
        <Select
            showSearch
            value={value}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
            options={(data || []).map((d) => ({
                value: d.value,
                label: d.label,
            }))}
            {...props}
        />
    );
};
