import {
    Card,
    Col,
    Dropdown,
    DatePicker,
    Input,
    Menu,
    Row,
    Space,
    Table,
    message,
} from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Axios from '../../../config/axios';
import GenerateSPCARDHistory from '../GenerateSPCARD/components/GenerateSPCARDHistory';
import ConfirmationPrintOutBulk from './components/ConfirmationPrintOutBulk';
import moment from 'moment';
import ConfirmationPrintOut from './components/ConfirmationPrintOut';
import FilterPrintOut from './components/FilterPrintOut';
import ExportGenerateSPCard from '../GenerateSPCARD/components/ExportGenerateSPCard';
import './Custom.css';
import Can from '../../../components/Can';
function PrintOutSPCard() {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState([
        {
            order: 'ascend',
            field: 'material',
        },
        {
            order: 'descend',
            field: 'diameter',
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState(null);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf('day').toISOString(),
        endDate: moment().endOf('day').toISOString(),
    });
    const [dateRangeValue, setDateRangeValue] = useState([moment(), moment()]);

    const [isVisiblePrintBulk, setIsVisiblePrintBulk] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [isExportVisible, setIsExportVisible] = useState(false);

    // individual print
    const [isPrintVisible, setIsPrintVisible] = useState(false);
    const [printId, setPrintId] = useState(null);
    const [printRemaining, setPrintRemaining] = useState(null);

    useEffect(() => {
        if (dateRange.startDate && dateRange.endDate) {
            getData();
        }

        if (dateRange.startDate === null && dateRange.endDate === null) {
            getData();
        }
    }, [keyword, page, perpage, sort, dateRange]);

    const handleDateRange = (dates) => {
        if (dates && dates.length === 2) {
            setDateRange({
                startDate: dates[0]
                    ? dates[0].startOf('day').toISOString()
                    : null,
                endDate: dates[1] ? dates[1].endOf('day').toISOString() : null,
            });
        } else {
            setDateRange({
                startDate: null,
                endDate: null,
            });
        }

        setDateRangeValue(dates);
    };

    const getData = (filters = null) => {
        setLoading(true);
        setSelectedRowKeys([]);

        const formattedStartDate = dateRange.startDate
            ? moment(dateRange.startDate).format('YYYY-MM-DD')
            : null;
        const formattedEndDate = dateRange.endDate
            ? moment(dateRange.endDate).format('YYYY-MM-DD')
            : null;

        Axios.get('/printout-spcard', {
            params: {
                keyword,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                filters,
                perpage: perpage,
                page,
                sort,
            },
        })
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        if (!Array.isArray(sorter)) {
            setSort([sorter]);
        } else {
            setSort(sorter);
        }
    };

    const onSelectRowChange = (newSelectedRowKeys) => {
        let selected = data.filter((d) => newSelectedRowKeys.includes(d._id));
        setSelectedRows(selected);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const handlePrint = (id, printRem) => {
        setIsPrintVisible(true);
        setPrintId(id);
        setPrintRemaining(printRem);
    };

    const handleReprint = (id) => {
        setLoading(true);
        Axios.get(`/printout-spcard/${id}/reprint`, {
            responseType: 'blob',
        })
            .then((res) => {
                var blob = new Blob([res.data], { type: 'application/pdf' });
                var blobURL = URL.createObjectURL(blob);
                window.open(blobURL);
                getData();
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const onFinishPrintBulk = () => {
        getData();
        selectedRowKeys([]);
    };

    const hasSelected = selectedRows.length > 0;

    const columns = [
        {
            title: 'Production Date',
            dataIndex: 'production_date',
            key: 'production_date',
            render: (date) => {
                return moment(date).format('DD MMMM YYYY');
            },
            sorter: {
                multiple: 3,
            },
        },
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            sorter: {
                multiple: 3,
            },
        },
        {
            title: 'Diameter',
            dataIndex: 'diameter',
            key: 'diameter',
            sorter: {
                multiple: 2,
            },
        },
        {
            title: 'Raw Material',
            dataIndex: 'raw_material',
            key: 'raw_material',
            sorter: true,
            render: (_, record) =>
                record.raw_material
                    ? record.raw_material
                    : record.spcard && record.spcard.raw_material,
        },
        {
            title: 'Panjang',
            dataIndex: 'panjang',
            key: 'panjang',
            sorter: {
                multiple: 4,
            },
        },
        {
            title: 'Print Total',
            dataIndex: 'print_total',
            key: 'print_total',
            sorter: true,
        },
        {
            title: 'Print Remaining',
            dataIndex: 'print_remaining',
            key: 'print_remaining',
            sorter: true,
        },
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            key: 'part_number',
            sorter: {
                multiple: 5,
            },
        },
        {
            title: 'Departemen',
            dataIndex: 'departemen',
            key: 'departemen',
            sorter: {
                multiple: 9,
            },
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            sorter: {
                multiple: 6,
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
            sorter: true,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            sorter: true,
        },

        {
            title: 'Thickness',
            dataIndex: 'thickness',
            key: 'thickness',
            sorter: true,
        },

        {
            title: 'Jenis Mesin',
            dataIndex: 'jenis_mesin',
            key: 'jenis_mesin',
            sorter: {
                multiple: 7,
            },
        },
        {
            title: 'No. Mesin',
            dataIndex: 'no_mesin',
            key: 'no_mesin',
            sorter: {
                multiple: 1,
            },
        },
        {
            title: 'Mesin Cutting',
            dataIndex: 'mesin_cutting',
            key: 'mesin_cutting',
            render: (_, record) =>
                record.mesin_cutting
                    ? record.mesin_cutting
                    : record.spcard && record.spcard.mesin_cutting,
        },
        {
            title: 'No. Memori',
            dataIndex: 'no_memori',
            key: 'no_memori',
            sorter: true,
        },
        {
            title: 'Main Process',
            dataIndex: 'main_process',
            key: 'main_process',
            sorter: true,
        },
        {
            title: 'Qty S.P. Card',
            dataIndex: 'qty_std',
            key: 'qty_std',
            sorter: {
                multiple: 10,
            },
        },
        {
            title: 'Qty Planning',
            dataIndex: 'qty_planning',
            key: 'qty_planning',
            sorter: {
                multiple: 11,
            },
        },
        {
            title: 'Total Label',
            dataIndex: 'total_label',
            key: 'total_label',
            sorter: true,
        },

        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            sorter: false,
            fixed: 'right',
            width: 50,
            render: (_, record) => {
                return (
                    <>
                        <Dropdown
                            className="pointer"
                            overlay={
                                <Menu>
                                    {record.total_histories > 0 &&
                                        record.total_histories <
                                            record.print_total && (
                                            <Menu.Item
                                                key="1"
                                                onClick={() =>
                                                    handlePrint(
                                                        record._id,
                                                        record.print_remaining,
                                                    )
                                                }
                                            >
                                                <PrinterOutlined /> Print
                                            </Menu.Item>
                                        )}
                                    {record.print_remaining == 0 && (
                                        <Menu.Item
                                            key="1"
                                            onClick={() =>
                                                handleReprint(record._id)
                                            }
                                        >
                                            <PrinterOutlined /> RePrint
                                        </Menu.Item>
                                    )}
                                </Menu>
                            }
                            trigger={['click']}
                        >
                            <span
                                className="pointer"
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="mdi mdi-dots-vertical" />
                            </span>
                        </Dropdown>
                    </>
                );
            },
        },
    ];

    const rowClassName = (record) => {
        return record.print_remaining <= 0 ? 'row-generate' : '';
    };

    return (
        <div>
            <ul className="breadcumb">
                <li className="active">Print Out S.P.CARD CUT</li>
            </ul>
            <Card>
                <Row justify="space-between" gutter={[16, 16]}>
                    <Col>
                        <Space>
                            <ConfirmationPrintOutBulk
                                isVisible={isVisiblePrintBulk}
                                setIsVisible={setIsVisiblePrintBulk}
                                onFinish={onFinishPrintBulk}
                                selected={selectedRows}
                                disabled={!hasSelected}
                            />

                            <Can accessTo="Export Print Out Spcard">
                                <ExportGenerateSPCard
                                    isVisible={isExportVisible}
                                    setIsVisible={setIsExportVisible}
                                />
                            </Can>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Can accessTo="Filter Print Out Spcard">
                                <DatePicker.RangePicker
                                    style={{ width: '100%' }}
                                    onCalendarChange={handleDateRange}
                                    value={dateRangeValue}
                                />
                            </Can>
                            <FilterPrintOut onFinish={getData} />
                            <Input.Search
                                allowClear="true"
                                onSearch={(value) => {
                                    setPage(1);
                                    setKeyword(value);
                                }}
                                placeholder="Search..."
                                inputStyle={{
                                    whiteSpace: 'nowrap',
                                    overflowX: 'auto',
                                }}
                                style={{ width: '25rem' }}
                            />
                        </Space>
                    </Col>
                </Row>
                <ConfirmationPrintOut
                    id={printId}
                    isVisible={isPrintVisible}
                    setIsVisible={setIsPrintVisible}
                    onFinish={getData}
                    printRemaining={printRemaining}
                />
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            scroll={{ x: 'max-content' }}
                            rowSelection={{
                                preserveSelectedRowKeys: false,
                                selectedRowKeys: selectedRowKeys,
                                onChange: onSelectRowChange,
                                getCheckboxProps: (record) => ({
                                    disabled: record.print_remaining <= 0,
                                    className:
                                        record.print_remaining <= 0
                                            ? 'row-generate'
                                            : '',
                                }),
                            }}
                            columns={columns}
                            dataSource={data}
                            pagination={{
                                current: page,
                                total,
                                showSizeChanger: true,
                                pageSizeOptions: [
                                    '10',
                                    '20',
                                    '50',
                                    '100',
                                    total.toString(),
                                ],
                            }}
                            loading={loading}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                            expandable={{
                                expandedRowKeys: expandedRowKeys,
                                onExpand: handleExpand,
                                expandedRowRender: (record) => (
                                    <GenerateSPCARDHistory
                                        key={`history-${record._id}`}
                                        id={record._id}
                                        rows={expandedRowKeys}
                                    />
                                ),
                            }}
                            rowClassName={rowClassName}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default PrintOutSPCard;
