import "./Sidebar.css";

function Sidebar({ toggle, menus }) {
  return (
    <div className="kelola-sidebar">
      <ul>
        {menus && menus.map((menu) => {
          return (
            <li
              key={menu.name}
              onClick={() => toggle(menu.name)}
              className={
                localStorage.getItem("active") === menu.name
                  ? "kelola-sidebar-active"
                  : ""
              }
            >
              <span>
                <i className={menu.icon} />
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Sidebar;
