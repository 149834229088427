import {
    Button,
    Col,
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Table,
    Upload,
    Card,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { url } from '../../constant/url';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    ImportOutlined,
    ExportOutlined,
} from '@ant-design/icons';
import fileDownload from 'js-file-download';

function Customer(history) {
    const columns = [
        {
            title: ' Code',
            dataIndex: 'code',
            key: 'code',
            sorter: true,
        },
        {
            title: ' Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: ' Alias',
            dataIndex: 'alias',
            key: 'alias',
            sorter: true,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            sorter: false,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: false,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            sorter: false,
        },

        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            sorter: false,
            fixed: 'right',
            width: 50,
            render: (id) => {
                return (
                    <>
                        <Dropdown
                            className="pointer"
                            overlay={
                                <Menu>
                                    <Menu.Item key="1">
                                        <Link to={`/customer/edit/${id}`}>
                                            {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
                                            <EditOutlined /> Edit
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="3">
                                        <span onClick={() => handleDelete(id)}>
                                            {/* <i className="mdi mdi-delete mr-5" /> */}
                                            <DeleteOutlined />
                                            Delete
                                        </span>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                        >
                            <span
                                className="pointer"
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="mdi mdi-dots-vertical" />
                            </span>
                        </Dropdown>
                    </>
                );
            },
        },
    ];

    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [error, setError] = useState(null);
    const [customer, setCustomer] = useState([]);
    const [buttonAccess, setButtonAccess] = useState(null);

    useEffect(() => {
        getData();
        const pathname = window.location.pathname.split('/');
        console.log(pathname);
        const currentUrl = pathname[1];
        const permissions =
            localStorage.getItem('menus') &&
            JSON.parse(localStorage.getItem('menus'));

        if (permissions) {
            const findPermission = permissions.find(
                (permission) => permission.url === currentUrl,
            );

            const findChildPermission = permissions
                .map((child) => {
                    return child.children.find(
                        (grandChild) => grandChild.url === currentUrl,
                    );
                })
                .filter((grandChild) => grandChild)[0];

            if (findChildPermission && findChildPermission.children) {
                if (findChildPermission.children) {
                    const findButtonAccessChildren =
                        findChildPermission.children
                            .filter((x) => x !== null)
                            .filter((x) => x.permission_type === 'button');
                    if (findButtonAccessChildren) {
                        setButtonAccess(
                            findButtonAccessChildren.filter((x) => x !== null),
                        );
                    }
                }
            }

            if (findPermission && findPermission.children) {
                const findButtonAccess = findPermission.children.filter(
                    (x) => x.permission_type === 'button',
                );
                if (findButtonAccess) {
                    setButtonAccess(findButtonAccess);
                }
            }
        }
    }, [keyword, page, perpage, sort]);

    const [data, setData] = useState({
        file: null,
    });

    const handleRemove = () => {
        setData({
            ...data,
            file: null,
        });
    };

    const handleExport = () => {
        axios
            .get(`${url}/customer/export`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, `Export customer.xlsx`);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const onClickExport = () => {
        handleExport();
    };

    // useEffect(() => {
    //   getData();
    // }, [keyword, page, perpage, sort]);

    const getData = () => {
        setLoading(true);
        axios
            .get(`${url}/customer`, {
                params: {
                    keyword,
                    columns: ['code', 'name', 'phone', 'email', 'address'],
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setCustomer(res.data);
                setTotal(res.data.total);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure ?',
            content: (
                <div>
                    <p>Deleted data cannot be restored</p>
                </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return;
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id);
            },
        });
    };

    const confirmDelete = (id) => {
        setLoading(true);
        axios
            .delete(`${url}/customer/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    const downloadTemplate = () => {
        axios
            .get(`${url}/customer/download`, {
                params: {
                    keyword,
                    columns: ['name', 'phone', 'email', 'address', 'code'],
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, 'template-customer.xlsx');
            });
    };

    const handleBeforeUpload = (files) => {
        setData({
            ...data,
            file: files,
        });

        return false;
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setData({
            ...data,
            file: null,
        });
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onSaveData = () => {
        setLoading(true);

        let fd = new FormData();

        fd.append('file', data.file);

        axios
            .post(`${url}/customer/import`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
                closeModal();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 422) {
                        setError(err.response.data.errors);
                    }
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                } else {
                    message.error('Backend not yet Started');
                }
                setLoading(false);
            });
    };

    return localStorage.getItem('token') ? (
        <div>
            <ul className="breadcumb">
                <li className="active">Customer</li>
            </ul>
            <div className="content">
                <Row justify="space-between">
                    <Col>
                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'customer/create',
                            ) && (
                                <Link to="/customer/create">
                                    <Button
                                        type="primary"
                                        style={{
                                            backgroundColor: '#1b2086',
                                            borderColor: '#1b2086',
                                        }}
                                    >
                                        <PlusOutlined />
                                        Add
                                    </Button>
                                </Link>
                            )}

                        <Modal
                            title="Import Customer"
                            visible={isModalVisible}
                            footer={null}
                            onCancel={closeModal}
                        >
                            <Card className="body-data">
                                <div name="control-hooks">
                                    <div name="file" label="File">
                                        <Fragment>
                                            <Upload
                                                onRemove={handleRemove}
                                                beforeUpload={
                                                    handleBeforeUpload
                                                }
                                                fileList={[]}
                                            >
                                                <Button
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    Choose File{' '}
                                                    <ExportOutlined />
                                                </Button>
                                            </Upload>
                                            {data.file && (
                                                <React.Fragment
                                                    children={[
                                                        <Button
                                                            onClick={
                                                                handleRemove
                                                            }
                                                            type="danger"
                                                            ghost
                                                        >
                                                            <DeleteOutlined />
                                                        </Button>,
                                                    ]}
                                                >
                                                    {data.file.name
                                                        ? data.file.name
                                                        : data.file}
                                                </React.Fragment>
                                            )}
                                        </Fragment>
                                    </div>
                                    <div>
                                        <Button
                                            loading={loading}
                                            onClick={onSaveData}
                                            type="primary"
                                            htmlType="submit"
                                            className="mr-button"
                                            style={{
                                                marginRight: 10,
                                                backgroundColor: '#1b2086',
                                                borderColor: '#1b2086',
                                            }}
                                        >
                                            Upload
                                        </Button>
                                        <Button
                                            htmlType="reset"
                                            onClick={handleRemove}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Modal>

                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'customer/import',
                            ) && (
                                <Button
                                    onClick={showModal}
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                        marginLeft: 10,
                                    }}
                                >
                                    <ImportOutlined />
                                    Import
                                </Button>
                            )}

                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'customer/export',
                            ) && (
                                <Button
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                        marginLeft: '10px',
                                    }}
                                    onClick={onClickExport}
                                >
                                    <ExportOutlined />
                                    Export
                                </Button>
                            )}

                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'customer/download',
                            ) && (
                                <Button
                                    onClick={downloadTemplate}
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                        marginLeft: '10px',
                                    }}
                                >
                                    <ExportOutlined />
                                    Download Template
                                </Button>
                            )}
                    </Col>
                    <Col>
                        <Input.Search
                            allowClear="true"
                            onSearch={(value) => {
                                setPage(1);
                                setKeyword(value);
                            }}
                            placeholder="Search..."
                        />
                    </Col>
                </Row>
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            columns={columns}
                            dataSource={customer.data}
                            pagination={{
                                current: page,
                                total,
                                showSizeChanger: true,
                            }}
                            loading={loading}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    ) : (
        <Redirect to="/login" />
    );
}

export default Customer;
