import {
  Button,
  Col,
  Input,
  message,
  Row,
  Form,
  Switch,
  Select,
  Typography,
  Layout,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./PermissionForm.css";
import axios from "axios";
import { url } from "../../constant/url";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;
const { TextArea } = Input;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function PermissionForm({ props, history, match }) {
  const [data, setData] = useState({
    name: "",
    description: "",
    url: "",
    icon: "",
    order_number: "",
    parent_id: "",
    permission_type: "",
    parent_name: "",
  });

  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [types, setTypes] = useState([]);
  const [parents, setParents] = useState([]);
  const { Option } = Select;

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };


  const handleChangeSelect = (name, value) => {
    setErrors(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/permission/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const { name, description, permissions } = res.data.data;
        setData({
          ...data,
          name,
          description,
          permissions,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPermissionType = async () => {
    await axios.get(`${url}/settings/find`, {
      params: {
        variable: "permission_type",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setTypes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
      });
  };

  const getParents = async () => {
    await axios
      .get(`${url}/permission/list-parent`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData({
          ...data,
          permissions: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectParent = (name, value) => {
    setErrors(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const handleReset = () => {
    setData({
      name: "",
      description: "",
      url: "",
      parent_name: "",
      order_number: "",
      icon: "",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post(
      `${url}/permission`,
      {
        name: data.name,
        description: data.description,
        url: data.url,
        icon: data.icon,
        order_number: data.order_number,
        parent_id: data.parent_id,
        parent_name: data.parent_name,
        permission_type: data.permission_type_name,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        history.push("/permission");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/permission">Permission</Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Permission" : "Add Permission"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                <label className="form-label">
                  Permission <span className="error-text">*</span>
                </label>
                <Input
                  value={data.name}
                  className={errors && errors.name ? "is-error" : ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
                {errors && errors.name && (
                  <span className="error-text">{errors.name[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">Description</label>
                <Input.TextArea
                  rows={6}
                  value={data.description}
                  className={errors && errors.description ? "is-error" : ""}
                  onChange={(e) => handleChange("description", e.target.value)}
                />
                {errors && errors.description && (
                  <span className="error-text">{errors.description[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  URL <span className="error-text">*</span>
                </label>
                <Input
                  value={data.url}
                  className={errors && errors.url ? "is-error" : ""}
                  onChange={(e) => handleChange("url", e.target.value)}
                />
                {errors && errors.url && (
                  <span className="error-text">{errors.url[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Icon <span className="error-text">*</span>
                </label>
                <Input
                  value={data.icon}
                  className={errors && errors.icon ? "is-error" : ""}
                  onChange={(e) => handleChange("icon", e.target.value)}
                />
                {errors && errors.icon && (
                  <span className="error-text">{errors.icon[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Order Number <span className="error-text">*</span>
                </label>
                <Input
                  value={data.order_number}
                  className={errors && errors.order_number ? "is-error" : ""}
                  onChange={(e) => handleChange("order_number", e.target.value)}
                />
                {errors && errors.order_number && (
                  <span className="error-text">{errors.order_number[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">Parent Name</label>
                <Select
                  style={{ width: 700 }}
                  labelInValue
                  className={errors && errors.order_number ? "is-error" : ""}
                  onChange={(value) =>
                    handleChangeSelectParent("parent", value, data.id)
                  }
                  onFocus={() => getParents("")}
                  showSearch
                  onSearch={(value) => getParents(value)}
                  filterOption={false}
                  value={{
                    key: data.parent_id,
                    label: data.parent_name,
                  }}
                >
                  {data.permissions &&
                    data.permissions.map((permission) => {
                      return (
                        <Option value={permission._id} key={permission._id}>
                          {permission.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.parent && (
                  <span className="error-text">{errors.parent[0]}</span>
                )}
              </div>

              <Form.Item label="Type">
                  <Select
                    labelInValue
                    onChange={(value) =>
                      handleChangeSelect("permission_type", value)
                    }
                    onFocus={() => getPermissionType()}
                    showSearch
                    onSearch={(value) => getPermissionType(value)}
                    filterOption={false}
                    value={{
                      key: data.permission_type_id,
                      label: data.permission_type_name,
                    }}
                  >
                    {types &&
                      types.map((type) => {
                        return (
                          <Option value={type.name} key={type.name}>
                            {type.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    borderColor: "#1b2086",
                  }}
                >
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    borderColor: "#1b2086",
                    marginLeft: 10,
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default PermissionForm;
