import {
    Button,
    Col,
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Table,
    Tag,
    Card,
    Layout,
    Typography,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { url } from '../../constant/url';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    MoreOutlined,
} from '@ant-design/icons';

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function Setting() {
    const [keyword, setKeyword] = useState('');
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(false);

    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'variable',
        order: 'ascend',
    });

    const handleSearch = (value) => {
        setPage(1);
        setKeyword(value);
    };

    useEffect(() => {
        getData();
    }, [keyword, page, perpage, sort]);

    const getData = () => {
        setLoading(true);
        axios
            .get(`${url}/settings`, {
                params: {
                    keyword,
                    columns: ['variable'],
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setSettings(res.data);
                setTotal(res.data.data.total);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure?',
            content: (
                <div>
                    <p>Deleted data cannot be restored</p>
                </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return;
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id);
            },
        });
    };

    const confirmDelete = (id) => {
        setLoading(true);
        axios
            .delete(`${url}/settings/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />;
    }

    const columns = [
        {
            title: 'Variable',
            dataIndex: 'variable',
            sorter: true,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            sorter: true,
            render: (value) =>
                value &&
                value.map((item) => {
                    return <Tag key={item.id}>{item.name}</Tag>;
                }),
        },
        {
            title: '',
            dataIndex: '_id',
            align: 'right',
            sorter: false,
            fixed: 'right',
            width: 100,
            render: (id) => {
                return (
                    <Fragment>
                        <Dropdown
                            className="pointer"
                            overlay={
                                <Menu>
                                    {/* <Menu.Item key="0">
                                    <Link to={`/setting/view/${id}`}><InfoCircleOutlined /> View</Link>
                                </Menu.Item> */}
                                    <Menu.Item key="1">
                                        <Link to={`/setting/edit/${id}`}>
                                            <EditOutlined /> Edit
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="3">
                                        <span onClick={() => handleDelete(id)}>
                                            <DeleteOutlined />
                                            Delete
                                        </span>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                        >
                            <span
                                className="pointer"
                                onClick={(e) => e.preventDefault()}
                            >
                                <MoreOutlined />
                            </span>
                        </Dropdown>
                    </Fragment>
                );
            },
        },
    ];

    console.log(settings);

    return (
        <Fragment>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row
                                type="flex"
                                gutter={[10, 20]}
                                justify="space-around"
                            >
                                <Col xs={24} md={12} lg={18}>
                                    <Link to="/setting/create">
                                        <Button
                                            type="primary"
                                            style={{
                                                backgroundColor: '#1b2086',
                                                borderColor: '#1b2086',
                                            }}
                                        >
                                            <PlusOutlined />
                                            Add Configuration
                                        </Button>
                                    </Link>
                                </Col>
                                <Col
                                    xs={24}
                                    md={12}
                                    lg={6}
                                    className="align-right"
                                >
                                    <Search
                                        placeholder="Search ..."
                                        onSearch={(value) =>
                                            handleSearch(value)
                                        }
                                    />
                                </Col>

                                <Col xs={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={settings.data}
                                        rowKey={(data) => data._id}
                                        onChange={handleTableChange}
                                        pagination={{
                                            total,
                                            pageSize: perpage,
                                            current: page,
                                            total: settings.total,
                                            showSizeChanger: true,
                                        }}
                                        loading={loading}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    );
}

export default Setting;
