import { message, Form, Input } from "antd";
import {
  MailOutlined,
  LockOutlined,
  HourglassOutlined,
} from "@ant-design/icons";
import logo from "../../assets/sanoh.png";
import axios from "axios";
import React, { useState } from "react";
import { Redirect } from "react-router";
import Button from "../../components/Button/Button";
import { url } from "../../constant/url";
import "./ResetPassword.css";
import { useLocation } from "react-router-dom";

function ResetPassword({ history, match }) {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const email = query.get("email");
  const [focus, setFocus] = useState({
    email,
    token,
    password: "",
    password_confirmation: "",
  });
  const [data, setData] = useState({
    email,
    token,
    password: "",
    password_confirmation: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSave = (e) => {
    // e.preventDefault();
    setLoading(true);
    axios
      .post(
        match.params.id
          ? `${url}/reset-password/${match.params.id}`
          : `${url}/reset-password`,
        {
          email: data.email,
          token: data.token,
          password: data.password,
          password_confirmation: data.password_confirmation,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/login");
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response.data.error);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  return !localStorage.getItem("token") ? (
    <div className="login">
      <img
        src={logo}
        className="kelola-logo"
        alt="logo"
        style={{
          width: 300,
          height: 200,
          display: "flex",
          // marginBottom: 10,
          margin: "auto",
        }}
      />
      <Form onFinish={handleSave}>
        <Form.Item
          validateStatus={error && error[0].email ? "error" : false}
          help={error && error[0].email ? error[0].email : false}
        >
          <Input
            disabled
            style={{ marginBottom: 2 }}
            className="login-input"
            prefix={<MailOutlined />}
            placeholder="Email Active"
            onChange={(e) => handleChange("email", e.target.value)}
            value={data.email}
          />
        </Form.Item>

        <Form.Item
          validateStatus={error && error.token ? "error" : false}
          help={error && error.token ? error.token[0] : false}
        >
          <Input
            disabled
            className="login-input"
            prefix={<HourglassOutlined />}
            placeholder="Token"
            onChange={(e) => handleChange("token", e.target.value)}
            value={data.token}
          />
        </Form.Item>
        <Form.Item
          validateStatus={error && error.password ? "error" : false}
          help={error && error.password ? error.password[0] : false}
        >
          <Input.Password
            className="login-input"
            prefix={<LockOutlined />}
            placeholder="Password"
            onChange={(e) => handleChange("password", e.target.value)}
            value={data.password}
          />
        </Form.Item>
        <Form.Item
          validateStatus={
            error && error.password_confirmation ? "error" : false
          }
          help={
            error && error.password_confirmation
              ? error.password_confirmation[0]
              : false
          }
        >
          <Input.Password
            className="login-input"
            prefix={<LockOutlined />}
            placeholder="Retype Password"
            onChange={(e) =>
              handleChange("password_confirmation", e.target.value)
            }
            value={data.password_confirmation}
          />
        </Form.Item>

        <div>
          <Button
            htmlType="submit"
            loading={loading}
            type="primary"
            className="button-login"
          >
            Reset Password
          </Button>
        </div>
      </Form>
    </div>
  ) : (
    <Redirect to="/dashboard" />
  );
}

export default ResetPassword;
