import { Button, Modal, Typography, message } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import Axios from '../../../../config/axios';

function ConfirmationPrintOutBulk({ isVisible, setIsVisible, onFinish, disabled = false, selected }) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const onCancel = () => {
        setIsVisible(false)
    }

    const onPrint = () => {
        let printable = selected.map(s => s._id);
        setLoading(true);
        Axios.post('printout-label/bulk', { printable }, {responseType: 'blob'})
            .then(({ data }) => {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                setIsVisible(false);
                onFinish();
            }).catch((error) => {
                if (error.response.status === 500) {
                    setIsVisible(false);
                    message.error('Server error');
                } else {
                    if (error.response) {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const errorMessage = JSON.parse(reader.result);
                            setIsVisible(false);
                            message.error(errorMessage.message);
                        };
                        reader.readAsText(error.response.data);
                    } else {
                        console.error('Error:', error.message.message);
                    }

                    // message.error(error.response.message);
                }
            }).finally(() => setLoading(false));
    }

    return (
        <>
            <Button type='primary' icon={<PrinterOutlined />} onClick={() => setIsVisible(true)} disabled={disabled}>Print Selected</Button>
            <Modal
                title="Print Label Internal"
                visible={isVisible}
                onCancel={onCancel}
                footer={[
                    <Button
                        key="btn-cancel"
                        type='ghost'
                        onClick={onCancel}>Cancel</Button>,
                    <Button
                        key="btn-import"
                        type='primary'
                        loading={loading}
                        onClick={onPrint}>Print</Button>,
                ]}
            >
                <Typography.Paragraph>Are you sure want to print all selected Label Internal?</Typography.Paragraph>
            </Modal>
        </>
    )
}

export default ConfirmationPrintOutBulk