import { Button, Form, InputNumber, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import Axios from '../../../../config/axios';

function PrintLabel({ isVisible, setIsVisible, onFinish, printRemaining, id }) {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const [form] = useForm();

    useEffect(() => {
        form.setFieldsValue({
            amount: printRemaining,
        });
    }, [id, form]);

    const onCancel = () => {
        setIsVisible(false)
    }

    const handlePrint = (data) => {
        if (data.amount === null || data.amount < 1) {
            message.error('You must specify amount!');
        } else {
            setLoading(true);
            Axios.post(`/generate-spcard/${id}/print`, data, { responseType: 'blob' }).then(({ data }) => {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                setIsVisible(false);
                onFinish();
            }).catch((error) => {
                if (error.response.status === 500) {
                    setIsVisible(false);
                    message.error('Server error');
                } else {
                    if (error.response) {
                        // If the error has a response object
                        const reader = new FileReader(); // Create a new FileReader object
                        reader.onload = function () {
                            const errorMessage = JSON.parse(reader.result);
                            setIsVisible(false);
                            message.error(errorMessage.message);
                        };
                        reader.readAsText(error.response.data); // Read the Blob data as text
                    } else {
                        console.error('Error:', error.message.message); // Log the error message if there is no response object
                    }

                    // message.error(error.response.message);
                }
            }).finally(() => setLoading(false))
        }
    }
    return (
        <Modal
            forceRender
            title="Print SP Card"
            visible={isVisible}
            onCancel={onCancel}
            footer={[
                <Button
                    key="btn-cancel"
                    type='ghost'
                    onClick={onCancel}>Cancel</Button>,
                <Button
                    key="btn-import"
                    type='primary'
                    loading={loading}
                    onClick={() => form.submit()}>Print</Button>,
            ]}
        >
            <Form
                name='print-label-form'
                layout='horizontal'
                form={form}
                onFinish={handlePrint}

            >
                <p>Masukkan jumlah S.P. Card yang akan diprint</p>
                <Form.Item
                    name="amount"
                    rules={[{ type: "number", min: 1, max: printRemaining, required: true }]}
                    label="Total Print"
                >
                    <InputNumber />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default PrintLabel