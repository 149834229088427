let url;
let socketUrl;
let storage_path;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // url = "http://api.sp.ns1.sanoh.co.id/api";
  // url = "http://192.168.18.19:8000/api";
  // url = "http://192.168.43.171:8000/api";
  url = "https://api.sanoh-traceability.kelola.biz/api";
  // url = "http://localhost:8001/api";
  // socketUrl = "http://192.168.100.114:3002";
  socketUrl = "http://10.1.10.39:3002";
  // storage_path = "http://localhost:8001/storage/images/";
  storage_path = "https://api.sanoh-traceability.kelola.biz/storage/images/";
} else {
  // url = "http://api.tc.ns1.sanoh.co.id/api";
  // storage_path = "http://api.tc.ns1.sanoh.co.id/storage/images/";
  url = "https://api.sanoh-traceability.kelola.biz/api";
  storage_path = "https://api.sanoh-traceability.kelola.biz/storage/images/";
  // url = "http://api.sp.ns1.sanoh.co.id/api";
  // url = "http://192.168.100.114:8000/api";
  socketUrl = "http://10.1.10.39:3002";
}

export { url, socketUrl,storage_path };

// examp
