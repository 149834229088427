import {
    Button,
    Col,
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Table,
    Card,
    Typography,
    Layout,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { url } from '../../constant/url';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;
function Role() {
    const columns = [
        {
            title: 'Role',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: false,
        },

        {
            title: '',
            dataIndex: '_id',
            key: '_id',
            sorter: false,
            fixed: 'right',
            width: 50,
            render: (id) => {
                return (
                    <>
                        <Dropdown
                            className="pointer"
                            overlay={
                                <Menu>
                                    <Menu.Item key="1">
                                        <Link to={`/role/edit/${id}`}>
                                            {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
                                            <EditOutlined /> Edit
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="3">
                                        <span onClick={() => handleDelete(id)}>
                                            {/* <i className="mdi mdi-delete mr-5" /> */}
                                            <DeleteOutlined />
                                            Delete
                                        </span>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                        >
                            <span
                                className="pointer"
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="mdi mdi-dots-vertical" />
                            </span>
                        </Dropdown>
                    </>
                );
            },
        },
    ];

    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [role, setRole] = useState([]);

    useEffect(() => {
        getData();
    }, [keyword, page, perpage, sort]);

    const getData = () => {
        setLoading(true);
        axios
            .get(`${url}/role`, {
                params: {
                    keyword,
                    columns: ['name'],
                    perpage: 10,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setRole(res.data);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure ?',
            content: (
                <div>
                    <p>Deleted data cannot be restored</p>
                </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return;
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id);
            },
        });
    };

    const confirmDelete = (id) => {
        setLoading(true);
        axios
            .delete(`${url}/role/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    return (
        <Fragment>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row
                                type="flex"
                                gutter={[10, 20]}
                                justify="space-around"
                            >
                                <Col xs={24} md={12} lg={18}>
                                    <Link to="/role/create">
                                        <Button
                                            type="primary"
                                            style={{
                                                backgroundColor: '#1b2086',
                                                borderColor: '#1b2086',
                                            }}
                                        >
                                            <PlusOutlined />
                                            Add Role
                                        </Button>
                                    </Link>
                                </Col>
                                <Col
                                    xs={24}
                                    md={12}
                                    lg={6}
                                    className="align-right"
                                >
                                    <Input.Search
                                        allowClear="true"
                                        onSearch={(value) => {
                                            setPage(1);
                                            setKeyword(value);
                                        }}
                                        placeholder="Search..."
                                    />
                                </Col>

                                <Col xs={24}>
                                    <Table
                                        columns={columns}
                                        dataSource={role.data}
                                        rowKey={(data) => data._id}
                                        onChange={handleTableChange}
                                        pagination={{
                                            current: page,
                                            pageSize: 10,
                                            total: role.total,
                                        }}
                                        loading={loading}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    );
}

export default Role;
