import "./BaseLayout.css";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import SidebarCollapsed from "../Sidebar/SidebarCollapsed";
import Footer from "../Footer/Footer";
import { useState } from "react";
import { Transition } from "react-transition-group";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { Redirect } from "react-router";

const transitionStyles = {
  entering: { marginLeft: -300 },
  entered: { marginLeft: 0 },
  exiting: { marginLeft: -300 },
  exited: { marginLeft: -300 },
};

function BaseLayout({ children, ...props }) {
  const [collapsed, setCollapsed] = useState(true);
  const [active, setActive] = useState(localStorage.getItem("item"));

  const handleToggle = (name) => {
    setActive(name);
    localStorage.setItem("active", name);
    if (active === name || !collapsed) {
      setCollapsed(!collapsed);
    }
  };

  useEffect(() => {
    const pathName = props.location.pathname;
    const getPathName = pathName.split("/");
    if (getPathName[1] === "production-planning") {
      setCollapsed(false);
    }
  }, [props.location.pathname]);

  useEffect(() => {
    const pathName = props.location.pathname;
    const getPathName = pathName.split("/");
    if (getPathName[1] === "dashboard") {
      setCollapsed(false);
    }
  }, [props.location.pathname]);

  const history = useHistory()
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/login');
    }
  }, [])

  const data = [];
  // const data = JSON.parse(localStorage.getItem("data"));
  const menus = JSON.parse(localStorage.getItem("menus"));
  return (
    <div className="kelola-layout">
      <Sidebar active={active} toggle={handleToggle} menus={menus} />
      <div className="kelola-navbar-sidebar-wrapper">
        <Navbar {...props} data={data} />
        <div className="kelola-sidebar-collapsed">
          <Transition in={collapsed} timeout={100} appear unmountOnExit>
            {(state) => {
              return (
                <SidebarCollapsed
                  {...props}
                  active={active}
                  menus={menus}
                  style={transitionStyles[state]}
                />
              );
            }}
          </Transition>

          <div className="kelola-content" style={{width: collapsed ? "calc(100% - 300px)" : "100%"}}>
            {children}
            <div className="kelola-footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BaseLayout;
