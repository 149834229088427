import { Card, Layout, Col, Row, message } from "antd";
import Axios from "axios";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
// import socketIoClient from "socket.io-client";
import { url, socketUrl } from "../../constant/url";
import "./Andon.css";
import moment from "moment";
import socketIo from "socket.io-client";

const { Content, Footer } = Layout;

function Andon(props) {
  const [data, setData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [part, setPart] = useState([]);
  const [partFirst, setPartFirst] = useState([]);
  const [problem, setProblem] = useState([]);
  const [hours, setHours] = useState([]);
  const [minutes, setminutes] = useState([]);
  const [productivity, setProductivity] = useState([]);
  const [unit, setUnit] = useState([]);
  const [planName, setPlanName] = useState([]);
  const [mainName, setMainName] = useState([]);
  const [lineName, setLineName] = useState([]);
  const [actplan, setActplan] = useState([]);

  const [sort, setSort] = useState({
    sort: "created_at",
    order: "desc",
  });
  const { plan, main, line } = props.match.params;
  const [dt, setDt] = useState(new Date());

  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const socket = socketIo(socketUrl);
    socket.on("andon", () => {
      getPart();
      getProblem();
      getLineStop();
      getJudul();
    });
    return () => {
      socket.off("andon");
    };
  }, []);

  useEffect(() => {
    let secTimer = setInterval(() => {
      setDt(new Date());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);

  useEffect(() => {
    console.log(window.location.href);
    getPart();
    getProblem();
    getLineStop();
    getJudul();
  }, [sort, page, perpage]);

  const getPart = async (epc) => {
    return Axios.get(`${url}/andon/part`, {
      params: {
        plan: plan,
        main: main,
        line: line,
        sort: sort.sort,
        order: sort.order,
        perpage: perpage,
        page,
      },
    })
      .then((res) => {
        setPart(res.data.data);
        setPartFirst(res.data.new_scan[0]);
        setProductivity(res.data.productivity);
        setTotal(res.data.total);
        setActplan(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const getProblem = async (epc) => {
    return Axios.get(`${url}/andon/problem`, {
      params: {
        plan: plan,
        main: main,
        line: line,
        sort: sort.sort,
        order: sort.order,
      },
    })
      .then((res) => {
        setProblem(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };
  // console.log("problem Name", problemName);

  const getLineStop = async (epc) => {
    return Axios.get(`${url}/andon/unit`, {
      params: {
        plan: plan,
        main: main,
        line: line,
        sort: sort.sort,
        order: sort.order,
      },
    })
      .then((res) => {
        setHours(res.data.hours);
        setminutes(res.data.minutes);
        setUnit(res.data.unit);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const getJudul = async (epc) => {
    return Axios.get(`${url}/andon/judul`, {
      params: {
        plan: plan,
        main: main,
        line: line,
        sort: sort.sort,
        order: sort.order,
      },
    })
      .then((res) => {
        setPlanName(res.data.plan_name);
        setMainName(res.data.main_process_name);
        setLineName(res.data.line_process_name);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const handleChangePage = (num) => {
    setPage(num);
    // setPage(next);
    // // setPage(pagination.currentPage);
    // setPerpage(pagination.pageSize);
  };

  const Pagination = () => {
    const numberOfItem = total;
    const numberPerpage = perpage;
    const currentPage = page;
    const numberOfPage = Math.ceil(numberOfItem / numberPerpage);

    // console.log();

    const pagings = [];
    for (let i = 0; i < numberOfPage; i++) {
      pagings.push(i);
    }

    console.log(pagings);

    return pagings.map((paging) => {
      return (
        <a
          style={{ justifyContent: "center" }}
          onClick={() => handleChangePage(paging + 1)}
          className={paging + 1 === currentPage ? "disable" : ""}
          key={paging}
        >
          {paging + 1}
        </a>
      );
    });
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24} style={{ color: "white" }}>
          <Card
            type="inner"
            title="Andon"
            extra={
              <div style={{ color: "white" }}>
                {moment(new Date()).format("DD MMMM YYYY H:mm:ss")}
              </div>
            }
          >
            {data.length > 0 && <p>Data will clear after {counter} second</p>}
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Card>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <span
              style={{
                color: "white",
                textAlign: "center",
                fontSize: 30,
                marginBottom: 25,
              }}
            >
              {/* {planName} - {mainName} - {lineName} */}
              {!planName ? "" : planName}
              {!mainName ? "" : " - " + mainName}
              {!lineName ? "" : " - " + lineName}
            </span>
          </div>

          <Row>
            <Col span={24}>
              <Row>
                <Col span={12}>
                  <table
                    style={{
                      width: "100%",
                      border: "1px solid white",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          border: "1px solid white",
                          textAlign: "center",
                          fontSize: "15pt",
                          color: "white",
                        }}
                        height="45"
                      >
                        LAST PART INPUT NUMBER
                      </th>
                    </tr>
                    <tr>
                      <td className="grid-green" height="100" width="100">
                        <h1 style={{ color: "white" }}>
                          <span style={{ fontSize: 60 }}>
                            {partFirst ? partFirst.part_number : 0}
                          </span>
                        </h1>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <table
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th className="grid-grey" height="35">
                        PLAN
                      </th>
                      <th width="50"></th>
                      <th className="grid-grey" height="35">
                        ACTUAL
                      </th>
                      <th width="50"></th>
                      <th className="grid-grey" height="35">
                        OUT
                      </th>
                    </tr>
                    <tr>
                      <td className="grid-yellow" height="110" width="220">
                        <span style={{ fontSize: 60 }}>
                          {actplan ? actplan.plan : 0}
                        </span>
                      </td>
                      <td></td>
                      <td className="grid-yellow" height="110" width="220">
                        <span style={{ fontSize: 60 }}>
                          {actplan ? actplan.actual : 0}
                        </span>
                      </td>
                      <td></td>
                      <td className="grid-yellow" height="110" width="220">
                        <span style={{ fontSize: 60 }}>
                          {actplan ? actplan.actual - actplan.plan : 0}
                        </span>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <table
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th className="grid-grey" height="35">
                        LINE STOP
                      </th>
                      <th width="50"></th>
                      <th className="grid-grey" height="35">
                        UNIT
                      </th>
                      <th width="50"></th>
                      <th className="grid-grey" height="35">
                        ACHIEVMENT
                      </th>
                    </tr>
                    <tr>
                      <td className="grid-yellow" height="110" width="220">
                        <span style={{ fontSize: 60 }}>
                          {hours}:{minutes}
                        </span>
                      </td>
                      <td></td>
                      <td className="grid-yellow" height="110" width="220">
                        <span style={{ fontSize: 60 }}>{Math.floor(unit)}</span>
                      </td>
                      <td></td>
                      <td className="grid-yellow" height="110" width="220">
                        <span style={{ fontSize: 60 }}>
                          {Math.floor(productivity)} %
                        </span>
                      </td>
                    </tr>
                  </table>
                </Col>
                <Col span={12}>
                  <table
                    pagination={{ showSizeChanger: true }}
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tr>
                      <th
                        colspan="4"
                        style={{
                          border: "1px solid white",
                          textAlign: "center",
                          color: "white",
                          fontSize: "15pt",
                        }}
                        height="45"
                      >
                        LAST PART INPUT NUMBER
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="grid-grey"
                        style={{ border: "1px solid white" }}
                        height="35"
                      >
                        PART NO
                      </th>
                      <th
                        className="grid-grey"
                        style={{ border: "1px solid white" }}
                      >
                        PLAN
                      </th>
                      <th
                        className="grid-grey"
                        style={{ border: "1px solid white" }}
                      >
                        ACTUAL
                      </th>
                      <th
                        className="grid-grey"
                        style={{ border: "1px solid white" }}
                      >
                        {" "}
                        OUTSTANDING
                      </th>
                    </tr>
                    {part.map((data) => {
                      return (
                        <tr
                          style={{
                            border: "1px solid white",
                            overflow: "scroll",
                            // backgroundColor: "red",
                          }}
                          height="41"
                        >
                          <td
                            style={{
                              textAlign: "center",
                              color: "white",
                            }}
                          >
                            {data.part_number} - {data.part_name}
                          </td>
                          <td
                            style={{
                              border: "1px solid white",
                              color: "white",
                            }}
                          >
                            {data.plan}
                          </td>
                          <td
                            style={{
                              border: "1px solid white",
                              color: "white",
                            }}
                          >
                            {data.ok}
                          </td>
                          <td
                            style={{
                              border: "1px solid white",
                              color: "white",
                            }}
                          >
                            {data.ok - data.plan}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                  {/* <div>
                    <a style={{ color: "white" }} href="#" class="previous">
                      &laquo; Previous
                    </a>

                    <a
                      style={{ marginLeft: 10, color: "white" }}
                      class="next"
                      onClick={() => handleNext()}
                    >
                      Next &raquo;
                    </a>
                  </div> */}

                  <div class="pagination">
                    <Pagination />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <table width="100%">
            <tr style={{ textAlign: "center" }} height="35">
              <td
                style={{
                  border: "1px solid white",

                  height: 50,
                }}
                width="25%"
              >
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "14pt",
                    height: 30,
                    color: "white",
                  }}
                >
                  <b>PROBLEM</b>
                </div>
              </td>
              <td style={{ border: "1px solid white", backgroundColor: "red" }}>
                <div
                  style={{
                    backgroundColor: "red",
                    fontSize: "14pt",
                    height: "center",
                    textAlign: "center",
                  }}
                >
                  <marquee>
                    {problem.map((data) => {
                      return (
                        <b style={{ color: "white" }}>
                          {data.cause} - {data.line_process_name}
                          &emsp;&emsp;&emsp;&emsp;&emsp;
                        </b>
                      );
                    })}
                  </marquee>
                </div>
              </td>
            </tr>
          </table>
        </Card>
      </Content>
    </Fragment>
  );
}

export default Andon;
