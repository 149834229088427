import {
    Avatar,
    Button,
    Col,
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Table,
    Upload,
    DatePicker,
    Card,
    Tag,
    Image,
} from 'antd';
import axios from 'axios';
import AxiosConf from '../../config/axios';
import React, { useEffect, useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { url, storage_path } from '../../constant/url';
import profile from '../../assets/profile.jpg';
import imgDefault from '../../assets/img-empty.png';
import {
    EditOutlined,
    EyeOutlined,
    DeleteOutlined,
    PlusOutlined,
    BookOutlined,
    ImportOutlined,
    ExportOutlined,
    RocketOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { render } from '@testing-library/react';
import ModalRev from '../../components/Elements/ModalRev';
import ModalImport from '../../components/Elements/ModalImport';
import Axios from '../../config/axios';
import ImportErrorModal from './ImportErrorModal';
import Can from '../../components/Can';
import DeleteConfirmModal from './DeleteConfrimModal';
const { TextArea } = Input;
const dateFormat = 'YYYY/MM/DD';
function MasterSpCard({ history }) {
    const getImageUrl = (part_number) => {
        const extensions = ['PNG', 'png', 'jpg', 'jpeg'];
        const urls = extensions.map(
            (ext) => `${storage_path}${part_number}.${ext}`,
        );
        return urls;
    };
    const columns = [
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            key: 'part_number',
            sorter: true,
            width: 200,
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
            sorter: true,
            width: 250,
        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
            sorter: true,
            width: 150,
        },
        {
            title: 'Department',
            dataIndex: 'plan_name',
            key: 'plan_name',
            sorter: true,
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            sorter: true,
        },
        {
            title: 'Material Code',
            dataIndex: 'material_code',
            key: 'material_code',
            sorter: true,
        },
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            sorter: true,
            width: 250,
        },
        {
            title: 'Diameter',
            dataIndex: 'diameter',
            key: 'Diameter',
            sorter: true,
        },
        {
            title: 'Thickness',
            dataIndex: 'thickness',
            key: 'thickness',
            sorter: true,
        },
        {
            title: 'Panjang',
            dataIndex: 'panjang',
            key: 'panjang',
            sorter: true,
        },
        {
            title: 'Toleransi',
            dataIndex: 'toleransi',
            key: 'toleransi',
            sorter: true,
        },
        {
            title: 'Jenis Mesin',
            dataIndex: 'jenis_mesin',
            key: 'jenis_mesin',
            sorter: true,
        },
        {
            title: 'Nomer Mesin',
            dataIndex: 'nomer_mesin',
            key: 'nomer_mesin',
            sorter: true,
        },
        {
            title: 'Mesin Cutting',
            dataIndex: 'mesin_cutting',
            key: 'mesin_cutting',
            sorter: true,
        },
        {
            title: 'No Memory',
            dataIndex: 'nomer_memori',
            key: 'nomer_memori',
            sorter: true,
        },
        {
            title: 'Stopper',
            dataIndex: 'stopper',
            key: 'stopper',
            sorter: true,
        },
        {
            title: 'Bending Mesin',
            dataIndex: 'bending_mesin',
            key: 'bending_mesin',
            sorter: true,
        },
        {
            title: 'Bending Jig',
            dataIndex: 'bending_jig',
            key: 'bending_jig',
            sorter: true,
        },
        {
            title: 'Packing',
            dataIndex: 'packing',
            key: 'packing',
            sorter: true,
        },
        {
            title: 'Raw Material',
            dataIndex: 'raw_material',
            key: 'raw_material',
            sorter: true,
        },
        {
            title: 'Panjang Raw Material',
            dataIndex: 'panjang_raw_material',
            key: 'panjang_raw_material',
            sorter: true,
        },
        {
            title: 'Negara Asal',
            dataIndex: 'negara_asal',
            key: 'negara_asal',
            sorter: true,
        },
        {
            title: 'Main Process',
            dataIndex: 'main_processes',
            key: 'main_process',
            sorter: true,
            width: 500,
        },
        {
            title: 'Sub Process',
            dataIndex: 'sub_processes',
            key: 'note',
            sorter: true,
            width: 800,
        },
        {
            title: 'Category Part',
            dataIndex: 'category_part',
            key: 'category_part',
            sorter: true,
            width: 200,
        },
      
        {
            title: 'Form Prefix',
            dataIndex: 'prefix',
            key: 'note',
            sorter: true,
        },
        {
            title: 'Form Infix',
            dataIndex: 'infix',
            key: 'note',
            sorter: true,
        },
        {
            title: 'Form Suffix',
            dataIndex: 'suffix',
            key: 'note',
            sorter: true,
        },

        // {
        //   title: "Sub Process Name",
        //   dataIndex: "sub_processes",
        //   key: "sub_processes",
        //   sorter: true,
        // },

        {
            title: 'Qty std',
            dataIndex: 'qty_std',
            key: 'qty_std',
            sorter: true,
        },
        {
            title: 'Qty int',
            dataIndex: 'qty_int',
            key: 'qty_int',
            sorter: true,
        },

        {
            title: 'Qty ERP ( SNP )',
            dataIndex: 'qty_erp',
            key: 'qty_erp',
            sorter: true,
        },

        {
            title: 'Line Internal',
            dataIndex: 'line_internal',
            key: 'line_internal',
            sorter: true,
        },

        {
            title: 'Route Internal',
            dataIndex: 'route_internal',
            key: 'route_internal',
            sorter: true,
        },

        {
            title: 'Revisi Last No',
            dataIndex: 'revisi_last_no',
            key: 'revisi_last_no',
            sorter: true,
            render: (revisi_last_no) => (revisi_last_no ? revisi_last_no : '-'),
        },
        // {
        //   title: "Main Process Name",
        //   dataIndex: "main_processes",
        //   key: "main_process",
        //   sorter: false,
        // },
        // {
        //   title: "Line Process Name",
        //   dataIndex: "line_process_name",
        //   key: "line_process",
        //   sorter: false,
        // },

        {
            title: 'Created',
            dataIndex: 'created_by',
            key: 'created_by',
            sorter: true,
        },

        {
            title: 'Checked',
            dataIndex: '-',
            key: '-',
            sorter: true,
        },
        {
            title: 'Approved',
            dataIndex: '-',
            key: '-',
            sorter: true,
        },
        {
            title: 'Image',
            width: 120,
            dataIndex: 'image',
            sorter: true,
            render: (image, part_number) => {
                const urls = getImageUrl(part_number.part_number);
                return (
                    <Image
                        src={image !== '-' ? part_number.image_url : imgDefault}
                        width={100}
                        height={70}
                    />
                );
            },
        },

        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            sorter: false,
            fixed: 'right',
            width: 50,
            render: (id, data) => {
                return (
                    <>
                        <Dropdown
                            className="pointer"
                            overlay={
                                <Menu>
                                    {buttonAccess &&
                                        buttonAccess.find(
                                            (x) =>
                                                x.url ===
                                                'master-spcard/preview',
                                        ) && (
                                            <Menu.Item key="4">
                                                <span
                                                    onClick={() =>
                                                        handlePreview(id)
                                                    }
                                                >
                                                    {/* <i className="mdi mdi-delete mr-5" /> */}
                                                    <EyeOutlined />
                                                    Preview
                                                </span>
                                            </Menu.Item>
                                        )}

                                    {buttonAccess &&
                                        buttonAccess.find(
                                            (x) =>
                                                x.url === 'master-spcard/edit',
                                        ) && (
                                            <Menu.Item key="1">
                                                <Link
                                                    to={`/master-spcard/edit/${id}`}
                                                >
                                                    {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
                                                    <EditOutlined /> Edit
                                                </Link>
                                            </Menu.Item>
                                        )}

                                    {buttonAccess &&
                                        buttonAccess.find(
                                            (x) =>
                                                x.url ===
                                                'master-spcard/edit-qty',
                                        ) && (
                                            <Menu.Item key="1">
                                                <Link
                                                    to={`/master-spcard/edit-qty/${id}`}
                                                >
                                                    {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
                                                    <EditOutlined /> Edit Qty
                                                </Link>
                                            </Menu.Item>
                                        )}

                                    {buttonAccess &&
                                        buttonAccess.find(
                                            (x) =>
                                                x.url ===
                                                'master-spcard/delete',
                                        ) && (
                                            <>
                                                <Menu.Item key="2">
                                                    <span
                                                        onClick={() =>
                                                            handleDelete(id)
                                                        }
                                                    >
                                                        {/* <i className="mdi mdi-delete mr-5" /> */}
                                                        <DeleteOutlined />
                                                        Delete
                                                    </span>
                                                </Menu.Item>
                                            </>
                                        )}
                                    {buttonAccess &&
                                        buttonAccess.find(
                                            (x) =>
                                                x.url ===
                                                'master-spcard/revisi',
                                        ) && (
                                            <Menu.Item key="3">
                                                {data.tbm_spcard_rev[0]
                                                    ?.rev_status == 3 ? (
                                                    <span
                                                        onClick={() =>
                                                            handleRevisi(id)
                                                        }
                                                        style={{
                                                            pointerEvents:
                                                                'none',
                                                            color: 'gray',
                                                        }}
                                                    >
                                                        {/* <i className="mdi mdi-delete mr-5" /> */}
                                                        <BookOutlined />
                                                        Revisi
                                                    </span>
                                                ) : (
                                                    <span
                                                        onClick={() =>
                                                            handleRevisi(id)
                                                        }
                                                    >
                                                        {/* <i className="mdi mdi-delete mr-5" /> */}
                                                        <BookOutlined />
                                                        Revisi
                                                    </span>
                                                )}
                                            </Menu.Item>
                                        )}
                                </Menu>
                            }
                            trigger={['click']}
                        >
                            <span
                                className="pointer"
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="mdi mdi-dots-vertical" />
                            </span>
                        </Dropdown>
                    </>
                );
            },
        },
    ];

    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisibleRev, setIsModalVisibleRev] = useState(false);
    const [isModalVisibleEditRev, setIsModalVisibleEditRev] = useState(false);
    const [perpage, setPerpage] = useState(10);
    const [newRevNo, setNewRevNo] = useState(0);
    const [sort, setSort] = useState({
        sort: 'diameter',
        order: 'ascend',
    });

    const [sortDetail, setSortDetail] = useState({
        sort: 'updated_at',
        order: 'descend',
    });

    const role = localStorage.getItem('data');
    const roleObject = JSON.parse(role);

    const roleName = roleObject.role_name;

    const [loading, setLoading] = useState(false);
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [spCardDetail, setSpCardDetail] = useState([]);
    const [pageDetail, setPageDetail] = useState(1);
    const [selectedId, setSelectedId] = useState('');
    const [perPageDetail, setPerPageDetail] = useState(10);
    const [rowDetails, setRowDetails] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [buttonAccess, setButtonAccess] = useState(null);
    const [importErrorVis, setImportErrorVis] = useState(false);
    const [importErrorData, setImportErrorData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [modalDeleteVis, setModalDeleteVis] = useState(false);

    const [data, setData] = useState({
        file: null,
    });
    const [dataRev, setDataRev] = useState({
        rev_no: '',
        rev_content: '',
        date_revision: null,
    });
    const [errors, setErrors] = useState(null);
    const [error, setError] = useState(null);
    const [spCard, setSpCard] = useState([]);
    const [spCardSelected, setSpCardSelected] = useState(null);
    const [revSelected, setSpCardRevSelected] = useState(null);
    const [keywordDetails, setKeywordDetails] = useState('');
    const [dateRevision, setDateRevision] = useState(
        new Date().toISOString().substr(0, 10),
    ); // Format: YYYY-MM-DD

    const handleChange = (name, value) => {
        setErrors({
            ...errors,
            [name]: '',
        });

        setDataRev({
            ...dataRev,
            [name]: value,
        });
    };

    useEffect(() => {
        getData();
        const pathname = window.location.pathname.split('/');
        const currentUrl = pathname[1];
        const permissions =
            localStorage.getItem('menus') &&
            JSON.parse(localStorage.getItem('menus'));

        if (permissions) {
            const findPermission = permissions.find(
                (permission) => permission.url === currentUrl,
            );

            const findChildPermission = permissions
                .map((child) => {
                    return child.children.find(
                        (grandChild) => grandChild.url === currentUrl,
                    );
                })
                .filter((grandChild) => grandChild)[0];

            if (findChildPermission && findChildPermission.children) {
                if (findChildPermission.children) {
                    const findButtonAccessChildren =
                        findChildPermission.children
                            .filter((x) => x !== null)
                            .filter((x) => x.permission_type === 'button');
                    if (findButtonAccessChildren) {
                        setButtonAccess(
                            findButtonAccessChildren.filter((x) => x !== null),
                        );
                    }
                }
            }

            if (findPermission && findPermission.children) {
                const findButtonAccess = findPermission.children.filter(
                    (x) => x.permission_type === 'button',
                );
                if (findButtonAccess) {
                    setButtonAccess(findButtonAccess);
                }
            }
        }
    }, [keyword, page, perpage, sort]);

    const getData = () => {
        setLoading(true);
        axios
            .get(`${url}/master-spcard`, {
                // params: {
                //   keyword,
                //   limit: perpage,
                //   page,
                //   sortBy: `${sort.sort}:${sort.order}`,
                // },
                params: {
                    keyword,
                    columns: [
                        'part_number',
                        'part_name',
                        'customer_name',
                        'model',
                        'material',
                        'diameter',
                        'thickness',
                        'panjang',
                        'jenis_mesin',
                        'nomer_mesin',
                        'nomer_memori',
                        'stopper',
                        'main_process_name',
                        'bending_mesin',
                        'raw_material',
                        'line_processes',
                        'sub_process',
                        'qty_std',
                        'main_processes',
                        'category_type',
                    ],
                    perpage: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setSpCard(res.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                    if (error.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const downloadTemplate = () => {
        axios
            .get(`${url}/master-spcard/download`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, 'template_master_spcard.xlsx');
            });
    };

    const handleExport = () => {
        setLoading(true);
        axios
            .get(`${url}/master-spcard/export`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, `Export master-spcard.xlsx`);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const handleBeforeUpload = (files) => {
        setData({
            ...data,
            file: files,
        });

        return false;
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setData({
            ...data,
            file: null,
        });
        setIsModalVisible(false);
    };

    const closeModalRev = () => {
        setIsModalVisibleRev(false);
    };

    const closeModalEditRev = () => {
        setIsModalVisibleEditRev(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleCancelRev = () => {
        setIsModalVisibleRev(false);
    };

    const handleReset = () => {
        setDataRev({
            rev_no: '',
            rev_time: '',
            rev_content: '',
        });
    };

    const handleEditRevisi = (id) => {
        const foundItem = spCardDetail.find((item) => item._id === id);
        if (foundItem) {
            setSpCardRevSelected(foundItem);

            setDataRev({
                ...dataRev,
                rev_no: foundItem.rev_no,
                rev_content: foundItem.rev_content,
                date_revision: foundItem.date_revision,
            });
            setIsModalVisibleEditRev(true);
        } else {
            console.error('Item with ID not found');
            // Handle case when item with ID is not found
        }
    };

    const handleRevisi = (id) => {
        const foundItem = spCard.data.find((item) => item._id === id);

        if (foundItem) {
            const revisions = foundItem.tbm_spcard_rev || [];
            let revNumbers = [];
            revisions.forEach((rev) => {
                if (rev.rev_no) {
                    revNumbers = revNumbers.concat(rev.rev_no);
                }
            });
            revNumbers.sort((a, b) => a - b);
            const lastRevNo =
                revNumbers.length > 0 ? revNumbers[revNumbers.length - 1] : 0;
            const newRevNo = lastRevNo + 1;

            setSpCardSelected(foundItem);
            setNewRevNo(newRevNo);
            setDataRev({
                ...dataRev,
                rev_no: newRevNo,
                date_revision: dateRevision,
            });
            setIsModalVisibleRev(true);
        } else {
            console.error('Item with ID not found');
        }
    };

    const handlePreview = (id) => {
        setLoading(true);
        axios
            .get(`${url}/master-spcard/${id}/preview`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                var blob = new Blob([res.data], { type: 'application/pdf' });
                var blobURL = URL.createObjectURL(blob);
                window.open(blobURL);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 422) {
                        setError(err.response.data.errors);
                    }
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                } else {
                    message.error('Backend not yet Started');
                }
                setLoading(false);
            });
    };

    const handlePending = (id) => {
        const foundItem = spCardDetail.find((item) => item._id === id);
        console.log(foundItem);

        if (foundItem) {
            setLoading(true);

            let fd = new FormData();

            fd.set('id', foundItem._id);
            fd.set('tbmspcard_id', foundItem.tbmspcard_id);

            axios
                .post(`${url}/master-spcard/revpending`, fd, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((res) => {
                    message.success(res.data.message);
                    getData();
                    getSpCardDetail();
                })
                .catch((err) => {
                    if (err.response) {
                        message.error(err.response.data.message);
                        if (err.response.status === 422) {
                            setError(err.response.data.errors);
                        }
                        if (err.response.status === 401) {
                            localStorage.removeItem('token');
                        }
                    } else {
                        message.error('Backend not yet Started');
                    }
                    setLoading(false);
                });
        } else {
            console.error('Item with ID not found');
            // Handle case when item with ID is not found
        }
    };

    const handleUnRelease = (id) => {
        const foundItem = spCardDetail.find((item) => item._id === id);
        console.log(foundItem);

        if (foundItem) {
            setLoading(true);

            let fd = new FormData();

            fd.set('id', foundItem._id);
            fd.set('tbmspcard_id', foundItem.tbmspcard_id);

            axios
                .post(`${url}/master-spcard/revunrelease`, fd, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((res) => {
                    message.success(res.data.message);
                    getData();
                    getSpCardDetail();
                })
                .catch((err) => {
                    if (err.response) {
                        message.error(err.response.data.message);
                        if (err.response.status === 422) {
                            setError(err.response.data.errors);
                        }
                        if (err.response.status === 401) {
                            localStorage.removeItem('token');
                        }
                    } else {
                        message.error('Backend not yet Started');
                    }
                    setLoading(false);
                });
        } else {
            console.error('Item with ID not found');
            // Handle case when item with ID is not found
        }
    };
    const handleRelease = (id) => {
        const foundItem = spCardDetail.find((item) => item._id === id);
        console.log(foundItem);

        if (foundItem) {
            setLoading(true);

            let fd = new FormData();

            fd.set('id', foundItem._id);
            fd.set('tbmspcard_id', foundItem.tbmspcard_id);

            axios
                .post(`${url}/master-spcard/revrelease`, fd, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((res) => {
                    message.success(res.data.message);
                    getData();
                    getSpCardDetail();
                })
                .catch((err) => {
                    if (err.response) {
                        message.error(err.response.data.message);
                        if (err.response.status === 422) {
                            setError(err.response.data.errors);
                        }
                        if (err.response.status === 401) {
                            localStorage.removeItem('token');
                        }
                    } else {
                        message.error('Backend not yet Started');
                    }
                    setLoading(false);
                });
        } else {
            console.error('Item with ID not found');
            // Handle case when item with ID is not found
        }
    };

    const handleReleaseAll = () => {
        setLoading(true);
        Axios.post('/master-spcard/revrelease/all', { data: selectedRowKeys })
            .then((res) => {
                message.success(res.data.message);
                getData();
                setSelectedRowKeys([]);
                setSelectedData([]);
            })
            .catch((res) => {
                if (res.response) {
                    const response = res.response.data;
                    message.error(response.message);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleOnDeleteAll = () => {
        setModalDeleteVis(true);
    };

    const handleFinsihDeleteAll = () => {
        setSelectedRowKeys([]);
        setSelectedData([]);
        setModalDeleteVis(false);
        getData();
    };

    const handlePublish = (id) => {
        const foundItem = spCardDetail.find((item) => item._id === id);
        console.log(foundItem);

        if (foundItem) {
            setLoading(true);

            let fd = new FormData();

            fd.set('id', foundItem._id);
            fd.set('tbmspcard_id', foundItem.tbmspcard_id);

            axios
                .post(`${url}/master-spcard/revpublish`, fd, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                })
                .then((res) => {
                    message.success(res.data.message);
                    getData();
                    getSpCardDetail();
                })
                .catch((err) => {
                    if (err.response) {
                        message.error(err.response.data.message);
                        if (err.response.status === 422) {
                            setError(err.response.data.errors);
                        }
                        if (err.response.status === 401) {
                            localStorage.removeItem('token');
                        }
                    } else {
                        message.error('Backend not yet Started');
                    }
                    setLoading(false);
                });
        } else {
            console.error('Item with ID not found');
            // Handle case when item with ID is not found
        }
    };

    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure ?',
            content: (
                <div>
                    <p>Deleted data cannot be restored</p>
                </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return;
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id);
            },
        });
    };

    const confirmDelete = (id) => {
        setLoading(true);
        axios
            .delete(`${url}/master-spcard/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleSave = (e) => {
        // console.log(data.email);
        e.preventDefault();

        console.log('cek data', dataRev);
        let fd = new FormData();

        fd.set('rev_no', dataRev.rev_no);
        fd.set('date_revision', dataRev.date_revision);
        fd.set('rev_content', dataRev.rev_content);
        fd.set('tbmspcard_id', spCardSelected._id);
        setLoading(true);

        axios
            .post(`${url}/master-spcard/revisi`, fd, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
                getSpCardDetail();
                closeModalRev();
            })
            .catch((err) => {
                if (err.response) {
                    setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    const handleSaveEditRev = (e) => {
        // console.log(data.email);
        e.preventDefault();

        console.log('cek data', dataRev);
        let fd = new FormData();

        fd.set('id', revSelected._id);
        fd.set('rev_no', dataRev.rev_no);
        fd.set('date_revision', dataRev.date_revision);
        fd.set('rev_content', dataRev.rev_content);
        fd.set('tbmspcard_id', revSelected.tbmspcard_id);
        setLoading(true);

        axios
            .post(`${url}/master-spcard/update-revisi`, fd, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
                getSpCardDetail();
                closeModalEditRev();
            })
            .catch((err) => {
                if (err.response) {
                    setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    const handleChangeDetailsTable = (pagination, filter, sorter) => {
        setPageDetail(pagination.current);
        setSortDetail({
            sort: sorter.field,
            order: sorter.order,
        });
        setPerPageDetail(pagination.pageSize);
    };

    const handleChangeDetails = (data) => {
        const index = data[data.length - 1];
        console.log(index);
        setRowDetails([index]);
        if (index) {
            const data2 = index.split(';');
            console.log('master spcard detail', data2);
            setSelectedId(data2[0]);
            getSpCardDetail(data2[0]);
        }
    };

    const onSelectRowChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedData(selectedRows);
    };

    const onClickExport = () => {
        handleExport();
    };

    const getSpCardDetail = (spcard_id = selectedId) => {
        setLoadingDetail(true);
        axios
            .get(`${url}/master-spcard/details/${spcard_id}`, {
                params: {
                    keyword: keywordDetails,
                    perpage: perPageDetail,
                    page: pageDetail,
                    sort: sortDetail.sort,
                    order: sortDetail.order,
                },

                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setSpCardDetail(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                } else {
                    message.error('Backend not yet Started');
                }
            })
            .finally(() => {
                setLoadingDetail(false);
            });
    };

    const handleRemove = () => {
        setData({
            ...data,
            file: null,
        });
    };
    const onSaveData = () => {
        setLoading(true);

        let fd = new FormData();

        fd.append('file', data.file);

        Axios.post(`${url}/master-spcard/import`, fd)
            .then((res) => {
                message.success(res.data.message);
                getData();
                closeModal();
            })
            .catch((err) => {
                if (err.response) {
                    const res = err.response.data;
                    message.warning(res.message);
                    setImportErrorData(res.data);
                    closeModal();
                    setImportErrorVis(true);
                } else {
                    message.error('Backend not yet Started');
                }
                setLoading(false);
            });
    };

    const getMasterDetail = () => {
        const columns = [
            {
                title: 'Rev No',
                dataIndex: 'rev_no',
                align: 'center',
                key: 'rev_no',
            },
            {
                title: 'Rev Content',
                dataIndex: 'rev_content',
                align: 'center',
                key: 'rev_content',
            },
            {
                title: 'Rev By',
                dataIndex: 'revised_by',
                align: 'center',
                key: 'revised_by',
            },
            {
                title: 'Date',
                dataIndex: 'date_revision',
                align: 'center',
                key: 'date_revision',
            },

            {
                title: 'Time',
                dataIndex: 'time_revision',
                align: 'center',
                key: 'time_revision',
            },

            {
                title: 'Status',
                dataIndex: 'rev_status',
                align: 'center',
                key: 'rev_status',
                render: (rev_status) => {
                    if (rev_status == 0) {
                        return <Tag color="green">Publish</Tag>;
                    } else if (rev_status == 1) {
                        return <Tag color="orange">Un Publish</Tag>;
                    } else if (rev_status == 3) {
                        return <Tag color="orange">Un Release</Tag>;
                    } else if (rev_status == 4) {
                        return <Tag color="green">Release</Tag>;
                    }
                },
            },

            {
                title: '',
                dataIndex: '_id',
                key: 'id',
                sorter: false,
                fixed: 'right',
                width: 50,
                render: (id, data) => {
                    return (
                        <>
                            <Dropdown
                                className="pointer"
                                overlay={
                                    <Menu>
                                        {buttonAccess &&
                                            buttonAccess.find(
                                                (x) =>
                                                    x.url === 'release-revisi',
                                            ) &&
                                            data.rev_status == 4 && (
                                                <Menu.Item key="4">
                                                    <span
                                                        onClick={() =>
                                                            handleUnRelease(id)
                                                        }
                                                    >
                                                        {/* <i className="mdi mdi-delete mr-5" /> */}
                                                        <RocketOutlined /> Un
                                                        Release SP CARD
                                                    </span>
                                                </Menu.Item>
                                            )}

                                        {buttonAccess &&
                                            buttonAccess.find(
                                                (x) =>
                                                    x.url === 'release-revisi',
                                            ) &&
                                            data.rev_status == 3 && (
                                                <Menu.Item key="4">
                                                    <span
                                                        onClick={() =>
                                                            handleRelease(id)
                                                        }
                                                    >
                                                        {/* <i className="mdi mdi-delete mr-5" /> */}
                                                        <RocketOutlined />{' '}
                                                        Release SP CARD
                                                    </span>
                                                </Menu.Item>
                                            )}
                                        {buttonAccess &&
                                            buttonAccess.find(
                                                (x) =>
                                                    x.url ===
                                                    'unpublish-revisi',
                                            ) &&
                                            data.rev_no != 0 && (
                                                <Menu.Item key="1">
                                                    <span
                                                        onClick={() =>
                                                            handlePending(id)
                                                        }
                                                    >
                                                        {/* <i className="mdi mdi-delete mr-5" /> */}
                                                        <EditOutlined /> Un
                                                        Publish Revisi
                                                    </span>
                                                </Menu.Item>
                                            )}
                                        {buttonAccess &&
                                            buttonAccess.find(
                                                (x) =>
                                                    x.url === 'publish-revisi',
                                            ) &&
                                            data.rev_no != 0 && (
                                                <Menu.Item key="3">
                                                    <span
                                                        onClick={() =>
                                                            handlePublish(id)
                                                        }
                                                    >
                                                        {/* <i className="mdi mdi-delete mr-5" /> */}
                                                        <EditOutlined /> Publish
                                                        Revisi
                                                    </span>
                                                </Menu.Item>
                                            )}
                                        {buttonAccess &&
                                            buttonAccess.find(
                                                (x) => x.url === 'edit-revisi',
                                            ) && (
                                                <Menu.Item key="2">
                                                    <span
                                                        onClick={() =>
                                                            handleEditRevisi(id)
                                                        }
                                                    >
                                                        {/* <i className="mdi mdi-delete mr-5" /> */}
                                                        <EditOutlined /> Edit
                                                        Revisi
                                                    </span>
                                                </Menu.Item>
                                            )}
                                    </Menu>
                                }
                                trigger={['click']}
                            >
                                <span
                                    className="pointer"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <i className="mdi mdi-dots-vertical" />
                                </span>
                            </Dropdown>
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Row>
                    <Col xs={24}>
                        {/* <RangePicker
                  onChange={handleChangeDate}
                  value={[date.from, date.to]}
                  format="DD MMM YYYY"
                  picker="date"
                  // style={{ marginBottom: "2%" }}
                /> */}

                        <Table
                            //scroll={{ x: 1000 }}
                            columns={columns}
                            rowKey={(data) => data._id}
                            loading={loadingDetail}
                            dataSource={spCardDetail}
                            // size="small"
                            onChange={handleChangeDetailsTable}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    return localStorage.getItem('token') ? (
        <div>
            <ul className="breadcumb">
                <li className="active">Master SP Card</li>
            </ul>
            <div className="content">
                <Row justify="space-between">
                    <Col>
                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'master-spcard/create',
                            ) && (
                                <Link to="/master-spcard/create">
                                    <Button
                                        type="primary"
                                        style={{
                                            backgroundColor: '#1b2086',
                                            borderColor: '#1b2086',
                                        }}
                                    >
                                        <PlusOutlined />
                                        Add
                                    </Button>
                                </Link>
                            )}

                        {/* Revisi SP Card */}
                        <ModalRev
                            title="Revisi S.P. Card"
                            visible={isModalVisibleRev}
                            onCancel={closeModalRev}
                            bodyTitle="Apakah yakin akan Revisi S.P. Card ?"
                            data={dataRev}
                            dataEdit={spCardSelected}
                            onHandleSave={handleSave}
                            newRevNo={newRevNo}
                            errors={errors}
                            onHandleChange={handleChange}
                            dateRevision={dateRevision}
                            onLoading={loading}
                            onHandleReset={handleReset}
                        />

                        {/* Edit Revisi */}
                        <ModalRev
                            title="Edit Revisi"
                            visible={isModalVisibleEditRev}
                            onCancel={closeModalEditRev}
                            bodyTitle="Apakah yakin akan Edit Revisi S.P. Card ?"
                            data={dataRev}
                            dataEdit={revSelected}
                            onHandleSave={handleSaveEditRev}
                            newRevNo={null}
                            errors={errors}
                            onHandleChange={handleChange}
                            dateRevision={null}
                            onLoading={loading}
                            onHandleReset={handleReset}
                        />

                        <ModalImport
                            title="Import SP Card"
                            data={data}
                            visible={isModalVisible}
                            onCancel={closeModal}
                            onRemove={handleRemove}
                            beforeUpload={handleBeforeUpload}
                            onHandleRemove={handleRemove}
                            onSaveData={onSaveData}
                            onLoading={loading}
                        />

                        <ImportErrorModal
                            data={importErrorData}
                            isVisible={importErrorVis}
                            setIsVisible={setImportErrorVis}
                        />

                        <DeleteConfirmModal
                            data={selectedData}
                            isVisible={modalDeleteVis}
                            setIsVisible={setModalDeleteVis}
                            onFinish={handleFinsihDeleteAll}
                        />

                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'master-spcard/import',
                            ) && (
                                <Button
                                    onClick={showModal}
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                        marginLeft: 10,
                                    }}
                                >
                                    <ImportOutlined />
                                    Import
                                </Button>
                            )}
                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'master-spcard/export',
                            ) && (
                                <Button
                                loading={loading}
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                        marginLeft: '10px',
                                    }}
                                    onClick={onClickExport}
                                >
                                    <ExportOutlined />
                                    Export
                                </Button>
                            )}
                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'master-spcard/template',
                            ) && (
                                <Button
                                    onClick={downloadTemplate}
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                        marginLeft: '10px',
                                    }}
                                >
                                    <ExportOutlined />
                                    Download Template
                                </Button>
                            )}

                        <Can accessTo="Create Master Sp Card">
                            <Button
                                type="primary"
                                style={{ marginLeft: '10px' }}
                                icon={<RocketOutlined />}
                                onClick={() => handleReleaseAll()}
                                disabled={selectedRowKeys.length === 0}
                            >
                                Release {selectedRowKeys.length > 1 && 'All'}
                            </Button>
                        </Can>
                        <Can accessTo="Create Master Sp Card">
                            <Button
                                type="primary"
                                style={{ marginLeft: '10px' }}
                                icon={<DeleteOutlined />}
                                onClick={() => handleOnDeleteAll()}
                                disabled={selectedRowKeys.length === 0}
                            >
                                Delete {selectedRowKeys.length > 1 && 'All'}
                            </Button>
                        </Can>
                    </Col>
                    <Col>
                        <Input.Search
                            allowClear="true"
                            onSearch={(value) => {
                                setPage(1);
                                setKeyword(value);
                            }}
                            placeholder="Search..."
                        />
                    </Col>
                </Row>
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            scroll={{ x: 'max-content' }}
                            columns={columns}
                            dataSource={spCard.data}
                            pagination={{
                                current: page,
                                total,
                                showSizeChanger: true,
                            }}
                            loading={loading}
                            expandable={{
                                expandedRowRender: getMasterDetail,
                                onExpandedRowsChange: (data) =>
                                    handleChangeDetails(data),
                                expandedRowKeys: rowDetails,
                            }}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                            rowSelection={{
                                preserveSelectedRowKeys: false,
                                selectedRowKeys,
                                onChange: onSelectRowChange,
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    ) : (
        <Redirect to="/login" />
    );
}

export default MasterSpCard;
