import { Button, Col, Input, message, Row, Avatar, Upload, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./PartForm.css";
import axios from "axios";
import { url } from "../../constant/url";
import profile from "../../assets/item.png";
import { LeftOutlined } from "@ant-design/icons";
import _ from "underscore";

const { Option } = Select;

function PartForm({ history, match, pane }) {
  const [data, setData] = useState({
    number: "",
    name: "",
    plan_id: "",
    plan_name: "",
    main_process_id: "",
    main_process_name: "",
    main_processes: [],
    line_process_id: "",
    line_process_name: "",
    line_processes: [],
    photo: "",
    photo_url: "",
  });

  const [page] = useState(1);
  const [perpage] = useState(10);
  const [sort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [mains, setMains] = useState([]);
  const [lines, setLines] = useState([]);

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/part/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const { number, name, plan_id, plan_name, photo_url } = res.data.data;
        setData({
          ...data,
          number,
          name,
          plan_id,
          plan_name,
          photo_url,
          main_processes: res.data.data.part_main_detail.map((x) => {
            return {
              key: x.main_process_id,
              value: x.main_process_id,
              label: x.main_process_name,
            };
          }),
          line_processes: res.data.data.part_line_detail.map((x) => {
            return {
              key: x.line_process_id,
              value: x.line_process_id,
              label: x.line_process_name,
            };
          }),
        });
        // console.log(res);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      name: "",
      number: "",
      main_processes: [],
      line_processes: [],
      photo: "",
      photo_url: "",
      plan_id: "",
      plan_name: "",
    });
  };

  // console.log(match);

  const handleSave = (e) => {
    e.preventDefault();
    let fd = new FormData();
    fd.set("name", data.name);
    fd.set("number", data.number);
    fd.set("plan_id", data.plan_id);
    fd.set("plan_name", data.plan_name);
    fd.set("main_processes", JSON.stringify(data.main_processes));
    fd.set("line_processes", JSON.stringify(data.line_processes));
    fd.append("photo", data.photo);
    setLoading(true);

    axios
      .post(
        match.params.id ? `${url}/part/${match.params.id}` : `${url}/part`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/part");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const handleBeforeUpload = (file) => {
    setData({
      ...data,
      photo_url: "",
      photo: file,
    });
    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      photo: "",
      photo_url: "",
    });
  };

  const handleChangeSelect = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        plan_id: "",
        plan_name: "",
      });
    }
  };

  const handleChangeSelectMultiple = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const getDepartment = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-plan`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDepartments(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getMain = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-main`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
          plan_id: data.plan_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMains(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getLine = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-line`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
          plan_id: data.plan_id,
          // main_process_id: data.main_process_id,
          main_process_id: JSON.stringify(
            _.pluck(data.main_processes, "value")
          ),
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLines(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  // console.log(errors);

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/part">
            <LeftOutlined />
            Part
          </Link>
        </li>
        <li className="active">{match.params.id ? "Edit Part" : "Add Part"}</li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Col span={12}></Col>
          <Col md={4} xs={20}>
            <div style={{ marginRight: 30 }} className="avatar-form">
              <div className="mb-20">
                <Avatar
                  src={
                    data.photo_url
                      ? data.photo_url
                      : data.photo
                      ? URL.createObjectURL(data.photo)
                      : profile
                  }
                  size={100}
                />
              </div>
              <Upload
                onRemove={handleRemove}
                beforeUpload={handleBeforeUpload}
                fileList={[]}
                accept="image/jpg, image/png"
              >
                {data.photo ? (
                  <Button type="danger" onClick={handleRemove}>
                    <i className="mdi mdi-close mr-5" />
                    Delete Foto
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#1b2086", marginBottom: 10 }}
                  >
                    <i className="mdi mdi-upload mr-5" />
                    Upload Foto
                  </Button>
                )}
              </Upload>
            </div>
          </Col>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                <label className="form-label">
                  Part Number <span className="error-text">*</span>
                </label>
                <Input
                  value={data.number}
                  className={errors && errors.number ? "is-error" : ""}
                  onChange={(e) => handleChange("number", e.target.value)}
                />
                {errors && errors.number && (
                  <span className="error-text">{errors.number[0]}</span>
                )}
              </div>

              <div className="form-group">
                <label className="form-label">
                  Part Name <span className="error-text">*</span>
                </label>
                <Input
                  value={data.name}
                  className={errors && errors.name ? "is-error" : ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
                {errors && errors.name && (
                  <span className="error-text">{errors.name[0]}</span>
                )}
              </div>
              <div className="form-group">
                Department <span className="error-text">*</span>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) => handleChangeSelect("plan", value)}
                  onFocus={() => getDepartment("")}
                  showSearch
                  onSearch={(value) => getDepartment(value)}
                  filterOption={false}
                  value={{
                    key: data.plan_id,
                    label: data.plan_name,
                  }}
                >
                  {departments &&
                    departments.map((dept) => {
                      return (
                        <Option value={dept._id} key={dept._id}>
                          {dept.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.plan_name && (
                  <span className="error-text">{errors.plan_name[0]}</span>
                )}
              </div>

              <div className="form-group">
                Main Process Name
                <Select
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectMultiple("main_processes", value)
                  }
                  onFocus={() => getMain("")}
                  showSearch
                  onSearch={(value) => getMain(value)}
                  filterOption={false}
                  mode="multiple"
                  value={data.main_processes}
                >
                  {mains &&
                    mains.map((main) => {
                      return (
                        <Option
                          value={main.main_process_id}
                          key={main.main_process_id}
                        >
                          {main.main_process_name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.main_processes && (
                  <span className="error-text">{errors.main_processes[0]}</span>
                )}
              </div>

              <div className="form-group">
                Line Process Name
                <Select
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectMultiple("line_processes", value)
                  }
                  onFocus={() => getLine("")}
                  showSearch
                  onSearch={(value) => getLine(value)}
                  filterOption={false}
                  mode={"multiple"}
                  value={data.line_processes}
                >
                  {lines &&
                    lines.map((line) => {
                      return (
                        <Option
                          value={line.line_process_id}
                          key={line.line_process_id}
                        >
                          {line.line_process_name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.line_processes && (
                  <span className="error-text">{errors.line_processes[0]}</span>
                )}
              </div>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default PartForm;
