import { useState } from "react";
import "./SidebarCollapsed.css";
import { Link } from "react-router-dom";

const childrenTransitionStyle = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

function SidebarCollapsed({ style, active, menus, ...props }) {
  const [childrenOpen, setChildrenOpen] = useState([]);

  const handleOpenChildren = (name) => {
    const newChildren = [...childrenOpen];
    const findChildren = newChildren.find((child) => child.name === name);
    if (findChildren) {
      findChildren.open = !findChildren.open;
      setChildrenOpen(newChildren);
    } else {
      setChildrenOpen([
        ...childrenOpen,
        {
          name,
          open: false,
        },
      ]);
    }
  };

  const pathName = props.location.pathname;
  const activeUrl = pathName.split("/")[1];
  const defaultActive = localStorage.getItem("active");
  const isActive = active ? active : defaultActive;
  const selectedMenu = menus
    ? menus.find((menu) => menu.name === isActive)
    : "";

  // const userData = {
  //   full_name: "Dhea",
  // };


  return (
    <div className="kelola-sidebar-collapsed-content" style={style}>
      <span className="kelola-menu-title">
        {selectedMenu && selectedMenu.name}
      </span>
      <ul>
        {selectedMenu &&
          selectedMenu.children.map((child) => {
            return child.children.length > 0 ? (
              <li
              key={child.url}
              className={`kelola-menu-hasnt-children ${
                child.url === activeUrl ? "active" : ""
              } `}
            >
              <Link to={`/${child.url}`}>{child.name}</Link>
            </li>
            ) : (
              <li
                key={child.url}
                className={`kelola-menu-hasnt-children ${
                  child.url === activeUrl ? "active" : ""
                } `}
              >
                <Link to={`/${child.url}`}>{child.name}</Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default SidebarCollapsed;
