import { Button, Col, Input, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./MachineForm.css";
import axios from "axios";
import { url } from "../../constant/url";
import { LeftOutlined } from "@ant-design/icons";

function MachineForm({ history, match }) {
  const [data, setData] = useState({
    name: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/machine/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const { name, description } = res.data.data;
        setData({
          ...data,
          name,
          description,
        });
        console.log(res);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      description: "",
      name: "",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        match.params.id
          ? `${url}/machine/${match.params.id}`
          : `${url}/machine`,
        {
          name: data.name,
          description: data.description,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/machine");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  console.log(errors);

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/machine">
            {" "}
            <LeftOutlined />
            Machine
          </Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Machine" : "Add Machine"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                <label className="form-label">
                  Machine <span className="error-text">*</span>
                </label>
                <Input
                  value={data.name}
                  className={errors && errors.name ? "is-error" : ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
                {errors && errors.name && (
                  <span className="error-text">{errors.name[0]}</span>
                )}
              </div>

              <div className="form-group">
                <label className="form-label">Description</label>
                <Input.TextArea
                  rows={6}
                  value={data.description}
                  className={errors && errors.description ? "is-error" : ""}
                  onChange={(e) => handleChange("description", e.target.value)}
                />
                {errors && errors.description && (
                  <span className="error-text">{errors.description[0]}</span>
                )}
              </div>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" />  */}
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  // loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" /> */}
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default MachineForm;
