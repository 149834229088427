import {
    Button,
    Col,
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    Row,
    Table,
    Card,
    Upload,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useState, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { url, storage_path } from '../../constant/url';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    ImportOutlined,
    ExportOutlined,
} from '@ant-design/icons';
import fileDownload from 'js-file-download';
import imgDefault from '../../assets/img-empty.png';

function MaterialItem(history) {
    const columns = [
        {
            title: 'Part Number',
            dataIndex: 'number',
            key: 'number',
            sorter: true,
        },
        {
            title: 'Part Name',
            dataIndex: 'name',
            key: 'name',
            sorter: false,
        },
        {
            title: 'Department',
            dataIndex: 'plan_name',
            key: 'plan_name',
            sorter: false,
        },

        // {
        //   title: "Customer",
        //   dataIndex: "customer_name",
        //   key: "customer_name",
        //   sorter: false,
        // },
        // {
        //   title: "Model",
        //   dataIndex: "model",
        //   key: "model",
        //   sorter: false,
        // },
        {
            title: 'Main Process Name',
            dataIndex: 'main_processes',
            key: 'main_processes',
            sorter: false,
        },
        {
            title: 'Line Process Name',
            dataIndex: 'line_processes',
            key: 'line_processes',
            sorter: false,
        },
        // {
        //   title: "Sub Process Name",
        //   dataIndex: "sub_process",
        //   key: "sub_process",
        //   sorter: false,
        // },
        {
            title: 'Photo',
            dataIndex: 'photo_url',
            key: 'photo_url',
            sorter: false,
            width: 120,
            render: (photo_url) =>
                photo_url && (
                    <img style={{ width: 120, height: 100 }} src={photo_url} />
                ),
        },
        {
            title: '',
            dataIndex: '_id',
            key: 'id',
            sorter: false,
            fixed: 'right',
            width: 50,
            render: (id) => {
                return (
                    <>
                        <Dropdown
                            className="pointer"
                            overlay={
                                <Menu>
                                    <Menu.Item key="1">
                                        <Link to={`/master-item/edit/${id}`}>
                                            {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
                                            <EditOutlined /> Edit
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="3">
                                        <span onClick={() => handleDelete(id)}>
                                            {/* <i className="mdi mdi-delete mr-5" /> */}
                                            <DeleteOutlined />
                                            Delete
                                        </span>
                                    </Menu.Item>
                                </Menu>
                            }
                            trigger={['click']}
                        >
                            <span
                                className="pointer"
                                onClick={(e) => e.preventDefault()}
                            >
                                <i className="mdi mdi-dots-vertical" />
                            </span>
                        </Dropdown>
                    </>
                );
            },
        },
    ];

    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [error, setError] = useState(null);
    const [MItem, setMItem] = useState([]);
    const [buttonAccess, setButtonAccess] = useState(null);

    useEffect(() => {
        getData();
    }, [keyword, page, perpage, sort]);

    useEffect(() => {
        getData();
        const pathname = window.location.pathname.split('/');
        console.log(pathname);
        const currentUrl = pathname[1];
        const permissions =
            localStorage.getItem('menus') &&
            JSON.parse(localStorage.getItem('menus'));

        if (permissions) {
            const findPermission = permissions.find(
                (permission) => permission.url === currentUrl,
            );

            const findChildPermission = permissions
                .map((child) => {
                    return child.children.find(
                        (grandChild) => grandChild.url === currentUrl,
                    );
                })
                .filter((grandChild) => grandChild)[0];

            if (findChildPermission && findChildPermission.children) {
                if (findChildPermission.children) {
                    const findButtonAccessChildren =
                        findChildPermission.children
                            .filter((x) => x !== null)
                            .filter((x) => x.permission_type === 'button');
                    if (findButtonAccessChildren) {
                        setButtonAccess(
                            findButtonAccessChildren.filter((x) => x !== null),
                        );
                    }
                }
            }

            if (findPermission && findPermission.children) {
                const findButtonAccess = findPermission.children.filter(
                    (x) => x.permission_type === 'button',
                );
                if (findButtonAccess) {
                    setButtonAccess(findButtonAccess);
                }
            }
        }
    }, [keyword, page, perpage, sort]);

    const getData = () => {
        setLoading(true);
        axios
            .get(`${url}/master-item`, {
                params: {
                    keyword,
                    columns: [
                        'number',
                        'name',
                        'main_process_name',
                        'line_processes',
                        'sub_process',
                        'plan_name',
                    ],
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setMItem(res.data);
                setTotal(res.data.total);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleDelete = (id) => {
        Modal.confirm({
            title: 'Are you sure ?',
            content: (
                <div>
                    <p>Deleted data cannot be restored</p>
                </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return;
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id);
            },
        });
    };

    const confirmDelete = (id) => {
        setLoading(true);
        axios
            .delete(`${url}/master-item/${id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    const downloadTemplate = () => {
        axios
            .get(`${url}/master-item/download`, {
                params: {
                    keyword,
                    columns: ['description', 'name', 'code'],
                    perpage: perpage,
                    page,
                    sort: sort.sort,
                    order: sort.order,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, 'template_master_item.xlsx');
            });
    };

    const handleBeforeUpload = (files) => {
        setData({
            ...data,
            file: files,
        });

        return false;
    };

    const handleExport = (delivery_note_no) => {

        setLoading(true);
      console.log(delivery_note_no);
      axios.get(`${url}/master-item/export`, {
        params: {
          keyword,
          columns: ["number", "name", "main_process_name","line_processes","sub_process","plan_name"],
          
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        responseType: 'blob'
      })
      .then(res => {
        setLoading(false);
      fileDownload(res.data, `Export master-item.xlsx`);
      })
      .catch(err => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
    };

    const onClickExport = () => {
        handleExport();
    };

    const handleRemove = () => {
        setData({
            ...data,
            file: null,
        });
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setData({
            ...data,
            file: null,
        });
        setIsModalVisible(false);
    };

    // const handleCancel = () => {
    //   setIsModalVisible(false);
    // };

    const onSaveData = () => {
        setLoading(true);

        let fd = new FormData();

        fd.append('file', data.file);

        axios
            .post(`${url}/master-item/import`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                message.success(res.data.message);
                getData();
                closeModal();
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response.data.type);
                    if (err.response.data.type === 'error') {
                        const errorMessage = err.response.data.message.replace(
                            /\n/g,
                            '<br>',
                        ); // Mengganti karakter baris baru (\n) dengan tag <br>
                        Modal.error({
                            title: 'Failed!',
                            content: (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: errorMessage,
                                    }}
                                />
                            ), // Menggunakan dangerouslySetInnerHTML untuk memasukkan HTML langsung
                        });
                    } else {
                        const errorsKey = Object.keys(err.response.data.errors);
                        const errors = err.response.data.errors;

                        message.open({
                            message: `Error!: ${err.response.data.message}`,
                            description: (
                                <div>
                                    Error Details:
                                    {errorsKey.map((error) => {
                                        return <div>{errors[error][0]}</div>;
                                    })}
                                </div>
                            ),
                        });
                    }

                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                } else {
                    message.error('Backend not yet Started');
                }
                setLoading(false);
            });
    };

    return localStorage.getItem('token') ? (
        <div>
            <ul className="breadcumb">
                <li className="active">Master Material Item</li>
            </ul>
            <div className="content">
                <Row justify="space-between">
                    <Col>
                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'master-item/create',
                            ) && (
                                <Link to="/master-item/create">
                                    <Button
                                        type="primary"
                                        style={{
                                            backgroundColor: '#1b2086',
                                            borderColor: '#1b2086',
                                        }}
                                    >
                                        <PlusOutlined />
                                        Add
                                    </Button>
                                </Link>
                            )}
                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'master-item/import',
                            ) && (
                                <Button
                                    onClick={showModal}
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                        marginLeft: 10,
                                    }}
                                >
                                    <ImportOutlined />
                                    Import
                                </Button>
                            )}
                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'master-item/export',
                            ) && (
                                <Button
                                loading={loading}
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                        marginLeft: '10px',
                                    }}
                                    onClick={onClickExport}
                                >
                                    <ExportOutlined />
                                    Export
                                </Button>
                            )}
                        {buttonAccess &&
                            buttonAccess.find(
                                (x) => x.url === 'master-item/download',
                            ) && (
                                <Button
                                    onClick={downloadTemplate}
                                    type="primary"
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                        marginLeft: '10px',
                                    }}
                                >
                                    <ExportOutlined />
                                    Download Template
                                </Button>
                            )}

                        <Modal
                            title="Import Material Item"
                            visible={isModalVisible}
                            footer={null}
                            onCancel={closeModal}
                        >
                            <Card className="body-data">
                                <div name="control-hooks">
                                    <div name="file" label="File">
                                        <Fragment>
                                            <Upload
                                                onRemove={handleRemove}
                                                beforeUpload={
                                                    handleBeforeUpload
                                                }
                                                fileList={[]}
                                            >
                                                <Button
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    Choose File{' '}
                                                    <ExportOutlined />
                                                </Button>
                                            </Upload>
                                            {data.file && (
                                                <React.Fragment
                                                    children={[
                                                        <Button
                                                            onClick={
                                                                handleRemove
                                                            }
                                                            type="danger"
                                                            ghost
                                                        >
                                                            <DeleteOutlined />
                                                        </Button>,
                                                    ]}
                                                >
                                                    {data.file.name
                                                        ? data.file.name
                                                        : data.file}
                                                </React.Fragment>
                                            )}
                                        </Fragment>
                                    </div>
                                    <div>
                                        <Button
                                            loading={loading}
                                            onClick={onSaveData}
                                            type="primary"
                                            htmlType="submit"
                                            className="mr-button"
                                            style={{
                                                marginRight: 10,
                                                backgroundColor: '#1b2086',
                                                borderColor: '#1b2086',
                                            }}
                                        >
                                            Upload
                                        </Button>
                                        <Button
                                            htmlType="reset"
                                            onClick={handleRemove}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Modal>
                    </Col>
                    <Col>
                        <Input.Search
                            allowClear="true"
                            onSearch={(value) => {
                                setKeyword(value);
                                setPage(1);
                            }}
                            placeholder="Search..."
                        />
                    </Col>
                </Row>
                <Row className="mt-40">
                    <Col xs={24}>
                        <Table
                            scroll={{ x: 1500 }}
                            columns={columns}
                            dataSource={MItem.data}
                            pagination={{ current: page, total, showSizeChanger: true }}
                            loading={loading}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    ) : (
        <Redirect to="/login" />
    );
}

export default MaterialItem;
