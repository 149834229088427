import { Button, Col, Input, message, Row, Avatar, Upload, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../constant/url";
import profile from "../../assets/profile.jpg";
import { LeftOutlined } from "@ant-design/icons";

const { Option } = Select;

function EditMasterArea({ history, match }) {
  const [data, setData] = useState({
    code: "",
    description: "",
   
    part_id: "",
    part_name: "",
    
    sub_process: "",
    main_process_id: "",
    main_process_name: "",
    main_processes: [],
    line_process_id: "",
    line_process_name: "",
    line_processes: [],
  
  });

  const [page] = useState(1);
  const [perpage] = useState(10);
  const [sort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [plans, setPlan] = useState([]);
  const [mainProcess, setMainProcess] = useState([]);
  const [lineProcess, setLineProcess] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(`${url}/master-area/${match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log(res.data.data);
        setData({
          ...data,
          code: res.data.data.code,
          description: res.data.data.description,
         
          plan_id: res.data.data.plan_id,
          plan_name: res.data.data.plan_name,
        
          sub_process: res.data.data.sub_process,
         
          main_processes: res.data.data.tbm_area_main_detail.map((x) => {
            return {
              key: x.main_process_id,
              value: x.main_process_id,
              label: x.main_process_name,
            };
          }),
          line_processes: res.data.data.tbm_area_line_detail.map((x) => {
            return {
              key: x.line_process_id,
              value: x.line_process_id,
              label: x.line_process_name,
            };
          }),
          
         
         
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  
  const handleReset = () => {
    setData({
        code: "",
       
        plan_id: "",
        plan_name: "",
        customer_id: "",
        customer_name: "",
        description: "",
        main_processes: [],
        line_processes: [],
       
    });
  };

  const handleBeforeUpload = (file) => {
    setData({
      ...data,
      image: file,
    });
    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      image: "",
    });
  };

  const handleSave = (e) => {
    // console.log(data.email);
    e.preventDefault();
    let fd = new FormData();
   
    fd.set("code", data.code);
    fd.set("description", data.description);

    fd.set("plan_id", data.plan_id);
    fd.set("plan_name", data.plan_name);
    fd.set("main_processes", JSON.stringify(data.main_processes));
    fd.set("line_processes", JSON.stringify(data.line_processes));
    fd.set("sub_process", data.sub_process);
  
    setLoading(true);

    console.log(data)
    axios
      .post(
        `${url}/master-area/${match.params.id}`, fd,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/master-area");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };



  const getDepartment = async (keyword) => {
    await axios
      .get(`${url}/plan/list`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setPlan(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getMainProcess = async (keyword) => {
    await axios
      .get(`${url}/main-process/list`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMainProcess(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getLineProcess = async (keyword) => {
    await axios
      .get(`${url}/line-process/list`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLineProcess(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };


  const getCustomer = async (keyword) => {
    await axios
      .get(`${url}/customer/list`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data.data)
        setCustomers(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };


  const handleChangeSelect = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        customer_id: "",
        customer_name: "",
        plan_id: "",
        plan_name: "",
      });
    }
  };
  const handleChangeSelectMultiple = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/master-spcard">
            <LeftOutlined />
            Master Item
          </Link>
        </li>
        <li className="active">
         Edit Master Item
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Col span={12}></Col>
          <Row gutter={20}>
          <Col md={8} xs={24}>
       
          <div className="form-group">
                <label className="form-label">
                  Code <span className="error-text">*</span>
                </label>
                <Input
                  value={data.code}
                  className={errors && errors.code ? "is-error" : ""}
                  onChange={(e) => handleChange("code", e.target.value)}
                />
                {errors && errors.code && (
                  <span className="error-text">{errors.code[0]}</span>
                )}
              </div>
               
                      
          <div className="form-group">
                <label className="form-label">
                  Sub Process <span className="error-text">*</span>
                </label>
                <Input
                  value={data.sub_process}
                  className={errors && errors.sub_process ? "is-error" : ""}
                  onChange={(e) => handleChange("sub_process", e.target.value)}
                />
                {errors && errors.sub_process && (
                  <span className="error-text">{errors.sub_process[0]}</span>
                )}
              </div>
             
              <div className="form-group">
              Department <span className="error-text">*</span>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) => handleChangeSelect("customer", value)}
                  onFocus={() => getDepartment("")}
                  showSearch
                  onSearch={(value) => getDepartment(value)}
                  filterOption={false}
                  value={{
                    key: data.plan_id,
                    label: data.plan_name,
                  }}
                >
                  {plans &&
                    plans.map((dept) => {
                      return (
                        <Option value={dept._id} key={dept._id}>
                          {dept.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.customer && (
                  <span className="error-text">{errors.customer[0]}</span>
                )}
              </div>
             
             
          </Col>
          <Col md={8} xs={24}>
         
              
              <div className="form-group">
                Line Process Name <span className="error-text">*</span>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectMultiple("line_processes", value)
                  }
                  onFocus={() => getLineProcess("")}
                  showSearch
                  onSearch={(value) => getLineProcess(value)}
                  filterOption={false}
                  mode={"multiple"}
                  value={data.line_processes}
                >
                  {lineProcess &&
                    lineProcess.map((dept) => {
                      return (
                        <Option value={dept._id} key={dept._id}>
                          {dept.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.line_process && (
                  <span className="error-text">{errors.line_process[0]}</span>
                )}
              </div>

             
             
              <div className="form-group">
                Main Process Name <span className="error-text">*</span>
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectMultiple("main_processes", value)
                  }
                  onFocus={() => getMainProcess("")}
                  showSearch
                  onSearch={(value) => getMainProcess(value)}
                  filterOption={false}
                  mode={"multiple"}
                  value={data.main_processes}
    
                >
                  {mainProcess &&
                    mainProcess.map((dept) => {
                      return (
                        <Option value={dept._id} key={dept._id}>
                          {dept.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.main_process && (
                  <span className="error-text">{errors.main_process[0]}</span>
                )}
              </div>

              <div className="form-group">
                <label className="form-label">
                  Description <span className="error-text">*</span>
                </label>
                <Input
                  value={data.description}
                  className={errors && errors.description ? "is-error" : ""}
                  onChange={(e) => handleChange("description", e.target.value)}
                />
                {errors && errors.description && (
                  <span className="error-text">{errors.description[0]}</span>
                )}
              </div>
             

              <div className="form-group" style={{marginTop : 40}}>
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" />  */}
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  // loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" /> */}
                  Reset
                </Button>
              </div>
          </Col>

  

          </Row>
          
        </form>
      </div>
    </div>
  );
}

export default EditMasterArea;
