import { Button, Col, Input, message, Row, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './OperatorForm.css';
import axios from 'axios';
import { url } from '../../constant/url';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import _ from 'underscore';
import Axios from '../../config/axios';

function OperatorForm({ history, match }) {
    const [data, setData] = useState({
        date: '',
        shift_id: '',
        shift_name: '',
        employee_id: '',
        employee_full_name: '',
        plan_id: '',
        plan_name: '',
        manager_id: '',
        manager_full_name: '',
        main_processes: [],
        line_processes: [],
        sub_processes: [],
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const { Option } = Select;

    const [lines, setLines] = useState([]);
    const [mains, setMains] = useState([]);
    const [users, setUsers] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [shifts, setShift] = useState([]);
    const [lineProcess, setLineProcess] = useState([]);
    const [mainProcess, setMainProcess] = useState([]);
    const [subProcess, setSubProcess] = useState([]);

    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });

    useEffect(() => {
        if (match.params.id) {
            getData();
        }
    }, []);

    const getData = () => {
        setLoading(true);
        Axios.get(`${url}/operator/${match.params.id}`)
            .then((res) => {
                setData({
                    ...data,
                    date: moment(res.data.data.date).format('DD MMMM YYYY'),
                    shift_id: res.data.data.shift_id,
                    shift_name: res.data.data.shift_name,
                    employee_id: res.data.data.employee_id,
                    employee_full_name: res.data.data.employee_full_name,
                    plan_id: res.data.data.plan_id,
                    plan_name: res.data.data.plan_name,
                    manager_id: res.data.data.manager_id,
                    manager_full_name: res.data.data.manager_full_name,
                    main_processes: res.data.data.operator_main.map((x) => {
                        return {
                            key: x.line_process_id,
                            value: x.line_process_id,
                            label: x.main_process_name,
                        };
                    }),
                    line_processes: res.data.data.operator_line.map((x) => {
                        return {
                            key: x.line_process_id,
                            value: x.line_process_id,
                            label: x.line_process_name,
                        };
                    }),
                    sub_processes: res.data.data.operator_sub.map((x) => {
                        return {
                            key: x.line_process_id,
                            value: x.line_process_id,
                            label: x.sub_process_name,
                        };
                    }),
                });
                // console.log(res);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleReset = () => {
        setData({
            date: '',
            shift_id: '',
            shift_name: '',
            time: '',
            employee_id: '',
            employee_full_name: '',
            plan_id: '',
            plan_name: '',
            manager_id: '',
            manager_full_name: '',
            main_processes: '',
            line_processes: '',
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post(
                match.params.id
                    ? `${url}/operator/${match.params.id}`
                    : `${url}/operator`,
                {
                    date: moment(data.date).format('DD MMMM YYYY'),
                    shift_id: data.shift_id,
                    shift_name: data.shift_name,
                    employee_id: data.employee_id,
                    employee_full_name: data.employee_full_name,
                    plan_id: data.plan_id,
                    plan_name: data.plan_name,
                    manager_id: data.manager_id,
                    manager_full_name: data.manager_full_name,
                    main_processes: JSON.stringify(data.main_processes),
                    sub_processes: JSON.stringify(data.sub_processes),
                    line_processes: JSON.stringify(data.line_processes),
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                },
            )
            .then((res) => {
                message.success(res.data.message);
                history.push('/operator');
            })
            .catch((err) => {
                if (err.response) {
                    setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    // const handleChangeSelect = (name, value) => {
    //   setErrors(null);

    //   setData({
    //     ...data,
    //     main_processes: [],
    //     line_processes: [],
    //     [`${name}_id`]: value.value,
    //     [`${name}_name`]: value.label,
    //     [`${name}_full_name`]: value.label,
    //   });
    // };

    const handleChangeSelect = (name, value) => {
        setErrors(null);
        if (value) {
            setData({
                ...data,
                main_processes: [],
                line_processes: [],
                sub_processes: [],
                [`${name}_id`]: value.value,
                [`${name}_name`]: value.label,
                [`${name}_full_name`]: value.label,
            });
        } else {
            setData({
                ...data,
                [`${name}_id`]: null,
                [`${name}_name`]: null,
                [`${name}_full_name`]: null,
            });
        }
    };

    const handleChangeSelectMultiple = (name, value) => {
        if (
            value &&
            value.length &&
            value.some((item) => item.value === 'all')
        ) {
            const mapData = (items, key) =>
                items.map((item) => ({
                    value: key === 'name' ? item['_id'] : item[key],
                    label: item[key],
                    key: key === 'name' ? item['_id'] : item[key],
                }));

            let allData;

            switch (name) {
                case 'main_processes':
                    allData = mainProcess
                        ? mapData(mainProcess, 'main_process')
                        : [];
                    break;
                case 'sub_processes':
                    allData = subProcess
                        ? mapData(subProcess, 'sub_process')
                        : [];
                    break;
                default:
                    allData = lineProcess ? mapData(lineProcess, 'name') : [];
                    break;
            }

            setData({ ...data, [name]: allData });
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const getMain = async (keyword) => {
        await axios
            .get(`${url}/mapping-process/list-main`, {
                params: {
                    keyword,
                    limit: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                    plan_id: data.plan_id,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setMains(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getMainProcess = async (keyword) => {
        await Axios.get(`${url}/line-process/listmain`, {
            params: {
                keyword,
                department: data.plan_name,
                limit: perpage,
                page,
                sortBy: `${sort.sort}:${sort.order}`,
            },
        })
            .then((res) => {
                setMainProcess(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getSubProcess = async (keyword) => {
        const listMainProcesses =
            data.main_processes.length > 0
                ? data.main_processes.map((item) => item.label)
                : [];
        if (listMainProcesses.length > 0) {
            await Axios.get(`${url}/line-process/listsub`, {
                params: {
                    keyword,
                    limit: perpage,
                    department: data.plan_name,
                    mainProcesses: listMainProcesses,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                },
            })
                .then((res) => {
                    setSubProcess(res.data.data);
                })
                .catch((err) => {
                    if (err.response) {
                        message.error(err.response.data.message);
                        if (err.response.status === 401) {
                            localStorage.removeItem('token');
                        }
                    }
                });
        }
    };

    const getLineProcess = async (keyword) => {
        const listMainProcesses =
            data.main_processes.length > 0
                ? data.main_processes.map((item) => item.label)
                : [];
        const listSubProcesses =
            data.sub_processes.length > 0
                ? data.sub_processes.map((item) => item.label)
                : [];
        if (listSubProcesses.length > 0) {
            await Axios.get(`${url}/line-process/list`, {
                params: {
                    keyword,
                    department: data.plan_name,
                    mainProcesses: listMainProcesses,
                    subProcesses: listSubProcesses,
                    limit: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                },
            })
                .then((res) => {
                    setLineProcess(res.data.data);
                })
                .catch((err) => {
                    if (err.response) {
                        message.error(err.response.data.message);
                        if (err.response.status === 401) {
                            localStorage.removeItem('token');
                        }
                    }
                });
        }
    };

    const getLine = async (keyword) => {
        await axios
            .get(`${url}/mapping-process/list-line`, {
                params: {
                    keyword,
                    limit: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                    plan_id: data.plan_id,
                    main_process_id: JSON.stringify(
                        _.pluck(data.main_processes, 'value'),
                    ),
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setLines(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getOperator = async (keyword) => {
        await axios
            .get(`${url}/user/list-user-role`, {
                params: {
                    keyword,
                    role_name: 'Operator,Admin',
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setUsers(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getManager = async (keyword) => {
        await axios
            .get(`${url}/user/list-user-role`, {
                params: {
                    keyword,
                    role_name: 'Manager',
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setUsers(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getShift = async (keyword) => {
        await axios
            .get(`${url}/shift/list`, {
                params: {
                    keyword,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setShift(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const getDepartment = async (keyword) => {
        await axios
            .get(`${url}/master-area/list/departement`, {
                params: {
                    keyword,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            .then((res) => {
                setDepartments(res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                    if (err.response.status === 401) {
                        localStorage.removeItem('token');
                    }
                }
            });
    };

    const handleChangeSelectEmployee = (name, value) => {
        setErrors(null);
        if (value) {
            const findEmployee = users.find((x) => x._id === value.value);
            if (findEmployee) {
                setData({
                    ...data,
                    plan_id: findEmployee.plan_id,
                    plan_name: findEmployee.plan_name,
                    main_processes: [],
                    sub_processes: [],
                    line_processes: [],
                    [`${name}_id`]: value.value,
                    [`${name}_full_name`]: value.label,
                });
            }
        } else {
            setData({
                ...data,
                plan_id: '',
                plan_name: '',
                main_processes: [],
                line_processes: [],
                sub_processes: [],
                [`${name}_id`]: null,
                [`${name}_full_name`]: null,
            });
        }

        // if (name === "employee") {
        //   const findEmployee = users.find(
        //     (employee) => employee.employee_id === value.value
        //   );
        //   setData({
        //     ...data,

        //     plan_name: findEmployee.plan_name,
        //     [`${name}_id`]: value.value,
        //     [`${name}_name`]: value.label,
        //   });
        // } else {
        //   setData({
        //     ...data,
        //     [`${name}_id`]: value.value,
        //     [`${name}_name`]: value.label,
        //   });
        // }
    };
    return (
        <div>
            <ul className="breadcumb">
                <li>
                    <Link to="/operator">
                        <LeftOutlined />
                        Operator Movement
                    </Link>
                </li>
                <li className="active">
                    {match.params.id
                        ? 'Edit Operator Movement'
                        : 'Add Operator Movement'}
                </li>
            </ul>
            <div className="content">
                <form onSubmit={handleSave}>
                    <Row gutter={20}>
                        <Col md={18} xs={24}>
                            {/* <div className="form-group">
                <label className="form-label">
                  Date <span className="error-text">*</span>
                </label>
                <DatePicker
                  style={{ width: 693 }}
                  value={match.params.id && moment(data.date)}
                  onChange={(value) => handleChange("date", value)}
                  format="DD MMMM YYYY"
                />
                {errors && errors.date && (
                  <span className="error-text">{errors.date[0]}</span>
                )}
              </div> 

              <div className="form-group">
                Shift <span className="error-text">*</span>
                <Select
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) => handleChangeSelect("shift", value)}
                  onFocus={() => getShift("")}
                  showSearch
                  onSearch={(value) => getShift(value)}
                  filterOption={false}
                  value={{
                    key: data.shift_id,
                    label: data.shift_name,
                  }}
                >
                  {shifts &&
                    shifts.map((shift) => {
                      return (
                        <Option value={shift._id} key={shift._id}>
                          {shift.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.shift_name && (
                  <span className="error-text">{errors.shift_name[0]}</span>
                )}
              </div> */}
                            <div className="form-group">
                                Employee Name{' '}
                                <span className="error-text">*</span>
                                <Select
                                    allowClear
                                    // disabled
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelectEmployee(
                                            'employee',
                                            value,
                                        )
                                    }
                                    onFocus={() => getOperator('')}
                                    showSearch
                                    onSearch={(value) => getOperator(value)}
                                    filterOption={false}
                                    value={{
                                        key: data._id,
                                        label: data.employee_full_name,
                                        // label: localStorage.getItem("full_name"),
                                    }}
                                    // value={localStorage.getItem("full_name")}
                                >
                                    {users &&
                                        users.map((user) => {
                                            return (
                                                <Option
                                                    value={user._id}
                                                    key={user._id}
                                                >
                                                    {user.full_name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {/* <Input
                  
                  value={localStorage.getItem("full_name")}
                  // className={errors && errors.name ? "is-error" : ""}
                  onChange={(e) => handleChange("full_name", e.target.value)}
                /> */}
                                {errors && errors.employee_full_name && (
                                    <span className="error-text">
                                        {errors.employee_full_name[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                Department <span className="error-text">*</span>
                                {/* <Input
                  value={data.plan_name}
                  onChange={(e) => handleChange("plan_name", e.target.value)}
                  rows={5}
                /> */}
                                <Select
                                    allowClear
                                    // disabled
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelect('plan', value)
                                    }
                                    onFocus={() => getDepartment('')}
                                    showSearch
                                    onSearch={(value) => getDepartment(value)}
                                    filterOption={false}
                                    value={{
                                        key: data.plan_id,
                                        label: data.plan_name,
                                    }}
                                >
                                    {departments &&
                                        departments.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept._id}
                                                    key={dept._id}
                                                >
                                                    {dept.name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.plan_name && (
                                    <span className="error-text">
                                        {errors.plan_name[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                Main Process Name
                                <span className="error-text">*</span>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelectMultiple(
                                            'main_processes',
                                            value,
                                        )
                                    }
                                    onFocus={() => getMainProcess('')}
                                    showSearch
                                    onSearch={(value) => getMainProcess(value)}
                                    filterOption={false}
                                    mode={'multiple'}
                                    value={data.main_processes}
                                >
                                    <Option key="all" value="all">
                                        SELECT ALL
                                    </Option>
                                    {mainProcess &&
                                        mainProcess.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept.main_process}
                                                    key={dept.main_process}
                                                >
                                                    {dept.main_process}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.main_process && (
                                    <span className="error-text">
                                        {errors.main_process[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                Sub Process Name
                                <span className="error-text">*</span>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelectMultiple(
                                            'sub_processes',
                                            value,
                                        )
                                    }
                                    onFocus={() => getSubProcess('')}
                                    showSearch
                                    onSearch={(value) => getSubProcess(value)}
                                    filterOption={false}
                                    mode={'multiple'}
                                    value={data.sub_processes}
                                >
                                    <Option key="all" value="all">
                                        SELECT ALL
                                    </Option>
                                    {subProcess &&
                                        subProcess.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept.sub_process}
                                                    key={dept.sub_process}
                                                >
                                                    {dept.sub_process}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.sub_process && (
                                    <span className="error-text">
                                        {errors.sub_process[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                Line Process Name
                                {/* <span className="error-text">*</span> */}
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    labelInValue
                                    onChange={(value) =>
                                        handleChangeSelectMultiple(
                                            'line_processes',
                                            value,
                                        )
                                    }
                                    onFocus={() => getLineProcess('')}
                                    showSearch
                                    onSearch={(value) => getLineProcess(value)}
                                    filterOption={false}
                                    mode={'multiple'}
                                    value={data.line_processes}
                                >
                                    <Option key="all" value="all">
                                        SELECT ALL
                                    </Option>
                                    {lineProcess &&
                                        lineProcess.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept._id}
                                                    key={dept._id}
                                                >
                                                    {dept.name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                                {errors && errors.line_process && (
                                    <span className="error-text">
                                        {errors.line_process[0]}
                                    </span>
                                )}
                            </div>
                            {/* <div className="form-group">
                Manager <span className="error-text">*</span>
                <Input
                  value={data.manager}
                  className={errors && errors.manager ? "is-error" : ""}
                  onChange={(e) => handleChange("manager", e.target.value)}
                />
                {errors && errors.manager && (
                  <span className="error-text">{errors.manager[0]}</span>
                )}
              </div> */}
                            {/* <div className="form-group">
                Supervisor <span className="error-text">*</span>
                <Select
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelect("manager", value, data._id)
                  }
                  onFocus={() => getManager("")}
                  showSearch
                  onSearch={(value) => getManager(value)}
                  filterOption={false}
                  value={{
                    key: data.manager_id,
                    label: data.manager_full_name,
                  }}
                >
                  {users &&
                    users.map((user) => {
                      return (
                        <Option value={user._id} key={user._id}>
                          {user.full_name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.user_full_name && (
                  <span className="error-text">{errors.user_full_name[0]}</span>
                )}
              </div> */}
                            <div className="form-group">
                                <Button
                                    type="primary"
                                    onClick={handleSave}
                                    loading={loading}
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    {/* <i className="mdi mdi-content-save mr-5" />  */}
                                    Submit
                                </Button>

                                <Button
                                    htmlType="reset"
                                    type="primary"
                                    onClick={handleReset}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: '#1b2086',
                                        marginLeft: 10,
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    {/* <i className="mdi mdi-content-save mr-5" /> */}
                                    Reset
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    );
}

export default OperatorForm;
