import { Button, Col, Input, message, Row, DatePicker, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./TesOperatorForm.css";
import axios from "axios";
import { url } from "../../constant/url";
import moment from "moment";
import { LeftOutlined } from "@ant-design/icons";
import _, { find } from "underscore";

function TesOperatorForm({ history, match }) {
  const [data, setData] = useState({
    date: "",
    shift_id: "",
    shift_name: "",
    employee_id: "",
    employee_full_name: "",
    plan_id: "",
    plan_name: "",
    manager_id: "",
    manager_full_name: "",
    main_processes: [],
    line_processes: [],
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const { Option } = Select;

  const [lines, setLines] = useState([]);
  const [mains, setMains] = useState([]);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [shifts, setShift] = useState([]);

  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/operator/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // const {
        //   date,
        //   shift_id,
        //   shift_name,
        //   employee_id,
        //   employee_full_name,
        //   plan_id,
        //   plan_name,
        //   main_processes,
        //   line_processes,
        //   manager_id,
        //   manager_full_name,
        // } = res.data.data;
        setData({
          ...data,
          date: moment(res.data.data.date).format("DD MMMM YYYY"),
          shift_id: res.data.data.shift_id,
          shift_name: res.data.data.shift_name,
          employee_id: res.data.data.employee_id,
          employee_full_name: res.data.data.employee_full_name,
          plan_id: res.data.data.plan_id,
          plan_name: res.data.data.plan_name,
          manager_id: res.data.data.manager_id,
          manager_full_name: res.data.data.manager_full_name,
          main_processes: res.data.data.operator_main.map((x) => {
            return {
              key: x.main_process_id,
              value: x.main_process_id,
              label: x.main_process_name,
            };
          }),
          line_processes: res.data.data.operator_line.map((x) => {
            return {
              key: x.line_process_id,
              value: x.line_process_id,
              label: x.line_process_name,
            };
          }),
        });
        // console.log(res);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      date: "",
      shift_id: "",
      shift_name: "",
      time: "",
      employee_id: "",
      employee_full_name: "",
      plan_id: "",
      plan_name: "",
      manager_id: "",
      manager_full_name: "",
      main_processes: "",
      line_processes: "",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        match.params.id
          ? `${url}/operator/${match.params.id}`
          : `${url}/operator`,
        {
          date: moment(data.date).format("DD MMMM YYYY"),
          shift_id: data.shift_id,
          shift_name: data.shift_name,
          employee_id: data.employee_id,
          employee_full_name: data.employee_full_name,
          plan_id: data.plan_id,
          plan_name: data.plan_name,
          manager_id: data.manager_id,
          manager_full_name: data.manager_full_name,
          main_processes: data.main_processes,
          line_processes: data.line_processes,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/tes-operator");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  // const handleChangeSelect = (name, value) => {
  //   setErrors(null);

  //   setData({
  //     ...data,
  //     main_processes: [],
  //     line_processes: [],
  //     [`${name}_id`]: value.value,
  //     [`${name}_name`]: value.label,
  //     [`${name}_full_name`]: value.label,
  //   });
  // };

  const handleChangeSelect = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
        [`${name}_full_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
        [`${name}_full_name`]: null,
      });
    }
  };

  const handleChangeSelectMain = (name, value) => {
    setErrors(null);

    if (value) {
      const findMain = mains.find((x) => x._id === value.value);
      if (findMain) {
        setData({
          ...data,
          main_process_id: findMain.main_process_id,
          main_process_name: findMain.main_process_name,
          main_processes: findMain.operator_main.map((x) => {
            return {
              key: x.main_process_id,
              value: x.main_process_id,
              label: x.main_process_name,
            };
          }),
          line_processes: findMain.operator_line.map((x) => {
            return {
              key: x.line_process_id,
              value: x.line_process_id,
              label: x.line_process_name,
            };
          }),
          [`${name}_id`]: value.value,
          [`${name}_name`]: value.label,
        });
      }
    } else {
      setData({
        ...data,
        [name]: value,
        main_process_id: "",
        main_process_name: "",
        main_processes: "",
        line_processes: "",
      });
    }
  };

  const handleChangeSelectMultiple = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const getMain = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-main`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
          plan_id: data.plan_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMains(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getLine = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-line`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
          plan_id: data.plan_id,
          mapping_process_id: JSON.stringify(
            _.pluck(data.main_processes, "value")
          ),
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLines(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getOperator = async (keyword) => {
    await axios
      .get(`${url}/user/list-user-role`, {
        params: {
          keyword,
          role_name: "Operator",
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getManager = async (keyword) => {
    await axios
      .get(`${url}/user/list-user-role`, {
        params: {
          keyword,
          role_name: "Manager",
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getShift = async (keyword) => {
    await axios
      .get(`${url}/shift/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setShift(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getDepartment = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-plan`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDepartments(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectEmployee = (name, value) => {
    setErrors(null);

    if (value) {
      const findEmployee = users.find((x) => x._id === value.value);
      if (findEmployee) {
        setData({
          ...data,
          plan_id: findEmployee.plan_id,
          plan_name: findEmployee.plan_name,
          main_processes: [],
          line_processes: [],
          [`${name}_id`]: value.value,
          [`${name}_full_name`]: value.label,
        });
      }
    } else {
      setData({
        ...data,
        plan_id: "",
        plan_name: "",
        main_processes: [],
        line_processes: [],
        [`${name}_id`]: null,
        [`${name}_full_name`]: null,
      });
    }

    // if (name === "employee") {
    //   const findEmployee = users.find(
    //     (employee) => employee.employee_id === value.value
    //   );
    //   setData({
    //     ...data,

    //     plan_name: findEmployee.plan_name,
    //     [`${name}_id`]: value.value,
    //     [`${name}_name`]: value.label,
    //   });
    // } else {
    //   setData({
    //     ...data,
    //     [`${name}_id`]: value.value,
    //     [`${name}_name`]: value.label,
    //   });
    // }
  };

  console.log(data.date);

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/tes-operator">
            <LeftOutlined />
            Tes Operator Movement
          </Link>
        </li>
        <li className="active">
          {match.params.id
            ? "Edit Tes Operator Movement"
            : "Add Tes Operator Movement"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              {/* <div className="form-group">
                <label className="form-label">
                  Date <span className="error-text">*</span>
                </label>
                <DatePicker
                  style={{ width: 693 }}
                  value={match.params.id && moment(data.date)}
                  onChange={(value) => handleChange("date", value)}
                  format="DD MMMM YYYY"
                />
                {errors && errors.date && (
                  <span className="error-text">{errors.date[0]}</span>
                )}
              </div> 

              <div className="form-group">
                Shift <span className="error-text">*</span>
                <Select
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) => handleChangeSelect("shift", value)}
                  onFocus={() => getShift("")}
                  showSearch
                  onSearch={(value) => getShift(value)}
                  filterOption={false}
                  value={{
                    key: data.shift_id,
                    label: data.shift_name,
                  }}
                >
                  {shifts &&
                    shifts.map((shift) => {
                      return (
                        <Option value={shift._id} key={shift._id}>
                          {shift.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.shift_name && (
                  <span className="error-text">{errors.shift_name[0]}</span>
                )}
              </div> */}
              <div className="form-group">
                Employee Name <span className="error-text">*</span>
                <Select
                  allowClear
                  // disabled
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectEmployee("employee", value)
                  }
                  onFocus={() => getOperator("")}
                  showSearch
                  onSearch={(value) => getOperator(value)}
                  filterOption={false}
                  value={{
                    key: data._id,
                    label: data.employee_full_name,
                    // label: localStorage.getItem("full_name"),
                  }}
                  // value={localStorage.getItem("full_name")}
                >
                  {users &&
                    users.map((user) => {
                      return (
                        <Option value={user._id} key={user._id}>
                          {user.full_name}
                        </Option>
                      );
                    })}
                </Select>
                {/* <Input
                  
                  value={localStorage.getItem("full_name")}
                  // className={errors && errors.name ? "is-error" : ""}
                  onChange={(e) => handleChange("full_name", e.target.value)}
                /> */}
                {errors && errors.employee_full_name && (
                  <span className="error-text">
                    {errors.employee_full_name[0]}
                  </span>
                )}
              </div>
              <div className="form-group">
                Department <span className="error-text">*</span>
                {/* <Input
                  value={data.plan_name}
                  onChange={(e) => handleChange("plan_name", e.target.value)}
                  rows={5}
                /> */}
                <Select
                  allowClear
                  // disabled
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) => handleChangeSelect("plan", value)}
                  onFocus={() => getDepartment("")}
                  showSearch
                  onSearch={(value) => getDepartment(value)}
                  filterOption={false}
                  value={{
                    key: data.plan_id,
                    label: data.plan_name,
                  }}
                >
                  {departments &&
                    departments.map((dept) => {
                      return (
                        <Option value={dept._id} key={dept._id}>
                          {dept.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.plan_name && (
                  <span className="error-text">{errors.plan_name[0]}</span>
                )}
              </div>
              <div className="form-group">
                Main Process Name<span className="error-text">*</span>
                <Select
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectMain("main_processes", value)
                  }
                  onFocus={() => getMain("")}
                  showSearch
                  onSearch={(value) => getMain(value)}
                  filterOption={false}
                  mode="multiple"
                  value={data.main_processes}
                  // value={{
                  //   key: data.main_process_id,
                  //   label: data.main_process_name,
                  // }}
                >
                  {mains &&
                    mains.map((main) => {
                      return (
                        <Option value={main._id} key={main._id}>
                          {main.main_process_name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.main_processes && (
                  <span className="error-text">{errors.main_processes[0]}</span>
                )}
              </div>
              <div className="form-group">
                Line Process Name
                {/* <span className="error-text">*</span> */}
                <Select
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectMultiple("line_processes", value)
                  }
                  onFocus={() => getLine("")}
                  showSearch
                  onSearch={(value) => getLine(value)}
                  filterOption={false}
                  mode={"multiple"}
                  value={data.line_processes}
                  // value={{
                  //   key: data.line_process_id,
                  //   label: data.line_process_name,
                  // }}
                >
                  {lines &&
                    lines.map((line) => {
                      return (
                        <Option
                          value={line.line_process_id}
                          key={line.line_process_id}
                        >
                          {line.line_process_name}
                        </Option>
                      );
                    })}
                </Select>
                {/* {errors && errors.line_process_name && (
                  <span className="error-text">
                    {errors.line_process_name[0]}
                  </span>
                )} */}
              </div>
              {/* <div className="form-group">
                Manager <span className="error-text">*</span>
                <Input
                  value={data.manager}
                  className={errors && errors.manager ? "is-error" : ""}
                  onChange={(e) => handleChange("manager", e.target.value)}
                />
                {errors && errors.manager && (
                  <span className="error-text">{errors.manager[0]}</span>
                )}
              </div> */}
              {/* <div className="form-group">
                Supervisor <span className="error-text">*</span>
                <Select
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelect("manager", value, data._id)
                  }
                  onFocus={() => getManager("")}
                  showSearch
                  onSearch={(value) => getManager(value)}
                  filterOption={false}
                  value={{
                    key: data.manager_id,
                    label: data.manager_full_name,
                  }}
                >
                  {users &&
                    users.map((user) => {
                      return (
                        <Option value={user._id} key={user._id}>
                          {user.full_name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.user_full_name && (
                  <span className="error-text">{errors.user_full_name[0]}</span>
                )}
              </div> */}
              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" />  */}
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  // loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" /> */}
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default TesOperatorForm;
