import { message, Form, Input, Tabs } from "antd";
import { MailOutlined } from "@ant-design/icons";
import logo from "../../assets/sanoh.png";
import axios from "axios";
import React, { useState } from "react";
import { Redirect } from "react-router";
import Button from "../../components/Button/Button";
import { url } from "../../constant/url";
import "./ForgotPassword.css";

const { TabPane } = Tabs;

function ForgotPassword(props) {
  const [tab, setTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [login] = useState({ username: "", password: "" });
  const [data, setData] = useState({
    email: "",
  });

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...login,
      [name]: value,
    });
  };

  // const handleSave = () => {
  //   // e.preventDefault();
  //   console.log("true");
  //   setLoading(true);
  //   axios
  //     .post(
  //       match.params.id
  //         ? `${url}/forgot-password/${match.params.id}`
  //         : `${url}/forgot-password`,
  //       {
  //         email: data.email,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       message.success(res.data.message);
  //       history.push("/forgot-password");
  //     })
  //     .catch((err) => {
  //       if (err.response) {
  //         setError(err.response.data.error);
  //         message.error(err.response.data.message);
  //       }
  //       setLoading(false);
  //     });
  // };

  const onSaveData = () => {
    setLoading(true);

    axios
      .post(
        `${url}/forgot-password`,
        {
          email: data.email,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/login");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            sessionStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleTab = (key) => {
    setTab(key);
  };

  return !localStorage.getItem("token") ? (
    <div className="login">
      <img
        src={logo}
        className="kelola-logo"
        alt="logo"
        style={{
          width: 300,
          height: 200,
          display: "flex",
          margin: "auto",
        }}
      />
      <Tabs activeKey={tab} onChange={handleTab}>
        <TabPane tab="Forgot Password" key="1">
          <Form onFinish={onSaveData}>
            <Form.Item
              validateStatus={error && error.email ? "error" : false}
              help={error && error.email ? error.email[0] : false}
            >
              <Input
                style={{ marginBottom: 2 }}
                className="login-input"
                prefix={<MailOutlined />}
                placeholder="Email"
                onChange={(e) => handleChange("email", e.target.value)}
                value={data.email}
              />
            </Form.Item>

            <div>
              <Button
                htmlType="submit"
                loading={loading}
                // onClick={onSaveData}
                type="primary"
                className="button-login"
              >
                Send Token
              </Button>
            </div>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  ) : (
    <Redirect to="/dashboard" />
  );
}

export default ForgotPassword;
