import { Button, Col, Input, message, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ItemCheckForm.css";
import axios from "axios";
import { url } from "../../constant/url";

const { Option } = Select;
function ItemCheckForm({ history, match }) {
  const [data, setData] = useState({
    main_process_name: "",
    main_process_id: "",
    line_processes: [],
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [mains, setMains] = useState([]);

  const [page] = useState(1);
  const [perpage] = useState(10);
  const [sort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/item-check/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const { main_process_id, main_process_name, item_check } =
          res.data.data;
        setData({
          ...data,
          main_process_id,
          main_process_name,
          item_check,
          line_processes: res.data.data.part_detail.map((x) => {
            return {
              key: x.line_process_id,
              value: x.line_process_id,
              label: x.line_process_name,
            };
          }),
        });
        console.log(res);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      line_processes: "",
      main_process_id: "",
      main_process_name: "",
      item_check: "",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        match.params.id
          ? `${url}/item-check/${match.params.id}`
          : `${url}/item-check`,
        {
          main_process_id: data.main_process_id,
          main_process_name: data.main_process_name,
          line_process_id: data.line_process_id,
          line_process_name: data.line_process_name,
          line_processes: data.line_processes,
          item_check: data.item_check,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/item-check");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  // const handleChangeSelect = (name, value) => {
  //   // console.log(data.main_process_id);

  //   const findMains = mains.find(
  //     (main) => main.main_process_id === value.value
  //   );
  //   console.log(findMains);
  //   if (findMains) {
  //     const line_processes_data = findMains.mapping_process_detail.map((x) => {
  //       return {
  //         value: x.line_process_id,
  //         key: x.line_process_id,
  //         label: x.line_process_name,
  //       };
  //     });

  //     console.log(line_processes_data);

  //     setData({
  //       ...data,
  //       main_process_id: value.value,
  //       main_process_name: value.label,
  //       line_processes: line_processes_data,
  //     });
  //   }

  //   setErrors(null);
  // };

  const handleChangeSelect = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    }
  };

  const getMain = async (keyword) => {
    await axios
      .get(`${url}/mapping-process`, {
        params: {
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMains(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  // console.log(errors);

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/item-check">Item Check</Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Item Check" : "Add Item Check"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                Main Process <span className="error-text">*</span>
                <Select
                  style={{ width: 695 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelect(
                      "main_process",
                      value,
                      data.main_process_id
                    )
                  }
                  onFocus={() => getMain("")}
                  showSearch
                  onSearch={(value) => getMain(value)}
                  filterOption={false}
                  value={{
                    key: data.main_process_id,
                    label: data.main_process_name,
                  }}
                >
                  {mains &&
                    mains.map((main_process) => {
                      return (
                        <Option
                          value={main_process.main_process_id}
                          key={main_process.main_process_id}
                        >
                          {main_process.main_process_name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.role_name && (
                  <span className="error-text">{errors.role_name[0]}</span>
                )}
              </div>
              {/* <div className="form-group">
                Item Check <span className="error-text">*</span>
                <Select
                  style={{ width: 695 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectMultiple("line_processes", value)
                  }
                  onFocus={() => getLine("")}
                  showSearch
                  onSearch={(value) => getLine(value)}
                  filterOption={false}
                  mode={"multiple"}
                  value={data.line_processes}
                >
                  {lines &&
                    lines.map((line) => {
                      return (
                        <Option value={line._id} key={line._id}>
                          {line.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.line_processes && (
                  <span className="error-text">{errors.line_processes[0]}</span>
                )}

                
              </div> */}

              <div className="form-group">
                <label className="form-label">
                  Item Check <span className="error-text">*</span>
                </label>
                <Input
                  value={data.item_check}
                  className={errors && errors.item_check ? "is-error" : ""}
                  onChange={(e) => handleChange("item_check", e.target.value)}
                />
                {errors && errors.item_check && (
                  <span className="error-text">{errors.item_check[0]}</span>
                )}
              </div>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" />  */}
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  // loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" /> */}
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default ItemCheckForm;
