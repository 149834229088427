import { Button, Col, Modal, Row, Table, Typography } from 'antd';
import React from 'react';

function ImportErrorModal({ isVisible, setIsVisible, data: record }) {
    const onCancel = () => {
        setIsVisible(false);
    };

    const columns = [
        {
            title: 'Part Number',
            dataIndex: 'part_number',
            key: 'part_number',
        },
        {
            title: 'Part Name',
            dataIndex: 'part_name',
            key: 'part_name',
        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
        },
        {
            title: 'Department',
            dataIndex: 'plan_name',
            key: 'plan_name',
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Material Code',
            dataIndex: 'material_code',
            key: 'material_code',
        },
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
        },
        {
            title: 'Diameter',
            dataIndex: 'diameter',
            key: 'Diameter',
        },
        {
            title: 'Thickness',
            dataIndex: 'thickness',
            key: 'thickness',
        },
        {
            title: 'Panjang',
            dataIndex: 'panjang',
            key: 'panjang',
        },
        {
            title: 'Jenis Mesin',
            dataIndex: 'jenis_mesin',
            key: 'jenis_mesin',
        },
        {
            title: 'Nomer Mesin',
            dataIndex: 'nomer_mesin',
            key: 'nomer_mesin',
        },
        {
            title: 'Mesin Cutting',
            dataIndex: 'mesin_cutting',
            key: 'mesin_cutting',
        },
        {
            title: 'No Memory',
            dataIndex: 'nomer_memori',
            key: 'nomer_memori',
        },
        {
            title: 'Stopper',
            dataIndex: 'stopper',
            key: 'stopper',
        },
        {
            title: 'Bending Mesin',
            dataIndex: 'bending_mesin',
            key: 'bending_mesin',
        },
        {
            title: 'Bending Jig',
            dataIndex: 'bending_jig',
            key: 'bending_jig',
        },
        {
            title: 'Packing',
            dataIndex: 'packing',
            key: 'packing',
        },
        {
            title: 'Raw Material',
            dataIndex: 'raw_material',
            key: 'raw_material',
        },
        {
            title: 'Main Process',
            dataIndex: 'main_processes',
            key: 'main_process',
            render: (_, record) =>
                record.main_processes.length > 0
                    ? record.main_processes.join(' | ')
                    : '',
        },
        {
            title: 'Line Process',
            dataIndex: 'sub_processes',
            key: 'note',
            render: (_, record) =>
                record.sub_processes.length > 0
                    ? record.sub_processes.join(' | ')
                    : '',
        },
        {
            title: 'Form Prefix',
            dataIndex: 'prefix',
            key: 'note',
        },
        {
            title: 'Form Infix',
            dataIndex: 'infix',
            key: 'note',
        },
        {
            title: 'Form Suffix',
            dataIndex: 'suffix',
            key: 'note',
        },
        {
            title: 'Qty std',
            dataIndex: 'qty_std',
            key: 'qty_std',
        },
        {
            title: 'Qty int',
            dataIndex: 'qty_int',
            key: 'qty_int',
        },

        {
            title: 'Qty erp',
            dataIndex: 'qty_erp',
            key: 'qty_erp',
        },
        {
            title: 'Total Problems',
            dataIndex: 'problem_count',
            key: 'problem_count',
        },
        {
            title: 'Problems',
            dataIndex: 'problems',
            key: 'problems',
            render: (_, record) => {
                return (
                    <ul>
                        {record.problems &&
                            record.problems.map((problem, index) => (
                                <li key={index}>{problem}</li>
                            ))}
                    </ul>
                );
            },
        },
    ];

    return (
        <Modal
            title="Data Gagal Import"
            visible={isVisible}
            onCancel={onCancel}
            width={1200}
            footer={[
                <Button key="btn-cancel" type="ghost" onClick={onCancel}>
                    Close
                </Button>,
            ]}
        >
            <Typography.Text>
                Gagal import {record.total_problem_data} dari{' '}
                {record.total_data} data. Ditemukan {record.total_problem}{' '}
                error. Data yang ditampilkan adalah data yang memiliki error
            </Typography.Text>
            <Row>
                <Col>
                <Table
                    scroll={{ x: 5000 }}
                    columns={columns}
                    dataSource={record.problem_data}
                    pagination={{
                        total: record.total_data,
                        showSizeChanger: true,
                    }}
                    rowKey={(data) => data.part_number}
                />
                </Col>
            </Row>
        </Modal>
    );
}

export default ImportErrorModal;
