import { Button, Col, Input, message, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './LineProcessForm.css';
import { url } from '../../constant/url';
import { LeftOutlined } from '@ant-design/icons';
import Axios from '../../config/axios';

const { Option } = Select;

function LineProcessForm({ history, match }) {
    const [data, setData] = useState({
        code: '',
        department: '',
        department_id: '',
        department_name: '',
        main_process: '',
        main_process_id: '',
        main_process_name: '',
        sub_process: '',
        sub_process_id: '',
        sub_process_name: '',
        line_process: '',
        line_process_id: '',
        line_process_name: '',
        description: '',
    });

    const [selectData, setSelectData] = useState({
        department: [],
        main_process: [],
        sub_process: [],
        line_process: [],
    });
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(10);
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'ascend',
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (match.params.id) {
            getData();
        }
    }, []);

    const handleChange = (name, value) => {
        setErrors({
            ...errors,
            [name]: '',
        });

        setData({
            ...data,
            [name]: value,
        });
    };

    const getData = () => {
        setLoading(true);
        Axios.get(`${url}/line-process/${match.params.id}`)
            .then((res) => {
                const {
                    name,
                    description,
                    code,
                    department,
                    main_process,
                    sub_process,
                } = res.data.data;
                setData({
                    ...data,
                    code,
                    line_process: name,
                    line_process_id: name,
                    line_process_name: name,
                    department,
                    department_id: department,
                    department_name: department,
                    main_process,
                    main_process_id: main_process,
                    main_process_name: main_process,
                    sub_process,
                    sub_process_id: sub_process,
                    sub_process_name: sub_process,
                    description,
                });
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getDepartment = async (keyword = null) => {
        await Axios.get(`${url}/master-area/list/departement`, {
            params: {
                keyword,
            },
        })
            .then((res) => {
                const data = res.data.data.map((dep) => {
                    return {
                        value: dep.name,
                        label: dep.name,
                    };
                });
                setSelectData({ ...selectData, department: data });
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const getMainProcess = async (keyword = null) => {
        await Axios.get(`${url}/line-process/listmain`, {
            params: {
                keyword,
                department: data.department_name,
                limit: perpage,
                page,
                sortBy: `${sort.sort}:${sort.order}`,
            },
        })
            .then((res) => {
                const data = res.data.data.map((main) => {
                    return {
                        value: main.main_process,
                        label: main.main_process,
                    };
                });
                setSelectData({ ...selectData, main_process: data });
            })
            .catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            });
    };

    const getSubProcess = async (keyword = null) => {
        const listMainProcesses = data.main_process_name
            ? [data.main_process_name]
            : [];
        if (listMainProcesses.length > 0) {
            Axios.get(`${url}/line-process/listsub`, {
                params: {
                    keyword,
                    limit: perpage,
                    department: data.department_name,
                    mainProcesses: listMainProcesses,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                },
            })
                .then((res) => {
                    const data = res.data.data.map((sub) => {
                        return {
                            value: sub.sub_process,
                            label: sub.sub_process,
                        };
                    });
                    setSelectData({ ...selectData, sub_process: data });
                })
                .catch((err) => {
                    if (err.response) {
                        message.error(err.response.data.message);
                    }
                });
        }
    };

    const getLineProcess = async (keyword = null) => {
        const listMainProcesses = data.main_process_name
            ? [data.main_process_name]
            : [];
        const listSubProcesses = data.sub_process_name
            ? [data.sub_process_name]
            : [];

        if (listSubProcesses.length > 0) {
            await Axios.get(`${url}/line-process/list`, {
                params: {
                    keyword,
                    department: data.plan_name,
                    mainProcesses: listMainProcesses,
                    subProcesses: listSubProcesses,
                    limit: perpage,
                    page,
                    sortBy: `${sort.sort}:${sort.order}`,
                },
            })
                .then((res) => {
                    const data = res.data.data.map((line) => {
                        return {
                            value: line.name,
                            label: line.name,
                        };
                    });
                    setSelectData({ ...selectData, line_process: data });
                })
                .catch((err) => {
                    if (err.response) {
                        message.error(err.response.data.message);
                    }
                });
        }
    };

    const handleChangeSelect = (name, value) => {
        setErrors(null);
        if (value) {
            const getSelectData = selectData[name].find(
                (x) => x.value === value,
            );
            setData({
                ...data,
                [`${name}_id`]: getSelectData.value,
                [`${name}_name`]: getSelectData.label,
            });
        } else {
            setData({
                ...data,
                [`${name}_id`]: data[name],
                [`${name}_name`]: data[name],
            });
        }
    };

    const handleSearch = (keyword, name) => {
        if (keyword) {
            const key = keyword.toUpperCase();
            setData({
                ...data,
                [name]: key,
                [`${name}_id`]: '',
                [`${name}_name`]: key,
            });

            switch (name) {
                case 'department':
                    getDepartment(key);
                    break;

                case 'main_process':
                    getMainProcess(key);
                    break;
                case 'sub_process':
                    getSubProcess(key);
                    break;

                default:
                    getLineProcess(key);
                    break;
            }
        }
    };

    const handleAddNewSelectData = (newValue, name) => {
        if (newValue) {
            const newData = { value: newValue, label: newValue };
            setData({
                ...data,
                [`${name}_id`]: newValue,
                [`${name}_name`]: newValue,
            });
            setSelectData({ ...selectData, [name]: [newData] });
        }
    };

    const handleSelectClear = (e) => {
        setData({ ...data, [e]: '' });
        setSelectData({ ...selectData, [e]: [] });
    };

    const handleReset = () => {
        setData({
            code: '',
            department: '',
            department_id: '',
            department_name: '',
            main_process: '',
            main_process_id: '',
            main_process_name: '',
            sub_process: '',
            sub_process_id: '',
            sub_process_name: '',
            line_process: '',
            line_process_id: '',
            line_process_name: '',
            description: '',
        });

        setSelectData({
            department: [],
            main_process: [],
            sub_process: [],
            line_process: [],
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        setLoading(true);
        Axios.post(
            match.params.id
                ? `${url}/line-process/${match.params.id}`
                : `${url}/line-process`,
            {
                code: data.code,
                department: data.department_name ?? data.department,
                main_process: data.main_process_name ?? data.main_process,
                sub_process: data.sub_process_name ?? data.sub_process,
                name: data.line_process_name ?? data.line_process,
                description: data.description,
            },
        )
            .then((res) => {
                message.success(res.data.message);
                history.push('/master-area');
            })
            .catch((err) => {
                if (err.response) {
                    setErrors(err.response.data.errors);
                    message.error(err.response.data.message);
                }
                setLoading(false);
            });
    };

    return (
        <div>
            <ul className="breadcumb">
                <li>
                    <Link to="/master-area">
                        <LeftOutlined />
                        Master Area / Line Process
                    </Link>
                </li>
                <li className="active">
                    {match.params.id ? 'Edit Line Process' : 'Add Line Process'}
                </li>
            </ul>
            <div className="content">
                <form onSubmit={handleSave}>
                    <Row gutter={20}>
                        <Col md={18} xs={24}>
                            <div className="form-group">
                                <label className="form-label">
                                    Code <span className="error-text">*</span>
                                </label>
                                <Input
                                    value={data.code}
                                    className={
                                        errors && errors.code ? 'is-error' : ''
                                    }
                                    onChange={(e) =>
                                        handleChange('code', e.target.value)
                                    }
                                />
                                {errors && errors.code && (
                                    <span className="error-text">
                                        {errors.code[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Department
                                    <span className="error-text">*</span>
                                </label>
                                <Select
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    className={
                                        errors && errors.department
                                            ? 'is-error'
                                            : ''
                                    }
                                    showSearch
                                    filterOption={false}
                                    onChange={(e) =>
                                        handleChangeSelect('department', e)
                                    }
                                    onFocus={() => getDepartment()}
                                    onSearch={(e) =>
                                        handleSearch(e, 'department')
                                    }
                                    onClear={() =>
                                        handleSelectClear('department')
                                    }
                                    value={data.department_id}
                                    notFoundContent={
                                        <div>
                                            <span>No department found</span>
                                            <Button
                                                style={{ marginLeft: '10px' }}
                                                onClick={() =>
                                                    handleAddNewSelectData(
                                                        data.department,
                                                        'department',
                                                    )
                                                }
                                            >
                                                Add New
                                            </Button>
                                        </div>}
                                >
                                    {selectData.department.length > 0 && (
                                        selectData.department.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept.value}
                                                    key={dept.value}
                                                >
                                                    {dept.label}
                                                </Option>
                                            );
                                        })
                                    )}
                                </Select>
                                {errors && errors.department && (
                                    <span className="error-text">
                                        {errors.department[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Main Process{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Select
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    className={
                                        errors && errors.main_process
                                            ? 'is-error'
                                            : ''
                                    }
                                    showSearch
                                    filterOption={false}
                                    onChange={(e) =>
                                        handleChangeSelect('main_process', e)
                                    }
                                    onFocus={() => getMainProcess()}
                                    onSearch={(e) =>
                                        handleSearch(e, 'main_process')
                                    }
                                    onClear={() =>
                                        handleSelectClear('main_process')
                                    }
                                    value={data.main_process_id}
                                    notFoundContent={
                                        <div>
                                            <span>No main process found</span>
                                            <Button
                                                style={{ marginLeft: '10px' }}
                                                onClick={() =>
                                                    handleAddNewSelectData(
                                                        data.main_process,
                                                        'main_process',
                                                    )
                                                }
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    }
                                >
                                    {selectData.main_process.length > 0 && (
                                        selectData.main_process.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept.value}
                                                    key={dept.value}
                                                >
                                                    {dept.label}
                                                </Option>
                                            );
                                        })
                                    )}
                                </Select>
                                {errors && errors.department && (
                                    <span className="error-text">
                                        {errors.department[0]}
                                    </span>
                                )}
                                {errors && errors.main_process && (
                                    <span className="error-text">
                                        {errors.main_process[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Sub Process
                                    <span className="error-text">*</span>
                                </label>
                                <Select
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    className={
                                        errors && errors.sub_process
                                            ? 'is-error'
                                            : ''
                                    }
                                    showSearch
                                    filterOption={false}
                                    onChange={(e) =>
                                        handleChangeSelect('sub_process', e)
                                    }
                                    onFocus={() => getSubProcess()}
                                    onSearch={(e) =>
                                        handleSearch(e, 'sub_process')
                                    }
                                    onClear={() =>
                                        handleSelectClear('sub_process')
                                    }
                                    value={data.sub_process_id}
                                    notFoundContent={
                                        <div>
                                            <span>No sub process found</span>
                                            <Button
                                                style={{ marginLeft: '10px' }}
                                                onClick={() =>
                                                    handleAddNewSelectData(
                                                        data.sub_process,
                                                        'sub_process',
                                                    )
                                                }
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    }
                                >
                                    {selectData.sub_process.length > 0 && (
                                        selectData.sub_process.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept.value}
                                                    key={dept.value}
                                                >
                                                    {dept.label}
                                                </Option>
                                            );
                                        })
                                    )}
                                </Select>
                                {errors && errors.sub_process && (
                                    <span className="error-text">
                                        {errors.sub_process[0]}
                                    </span>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="form-label">
                                    Line Process{' '}
                                    <span className="error-text">*</span>
                                </label>
                                <Select
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    className={
                                        errors && errors.namme ? 'is-error' : ''
                                    }
                                    showSearch
                                    filterOption={false}
                                    onChange={(e) =>
                                        handleChangeSelect('line_process', e)
                                    }
                                    onFocus={() => getLineProcess()}
                                    onSearch={(e) =>
                                        handleSearch(e, 'line_process')
                                    }
                                    onClear={() =>
                                        handleSelectClear('line_process')
                                    }
                                    value={data.line_process_id}
                                    notFoundContent={
                                        <div>
                                            <span>No line process found</span>
                                            <Button
                                                style={{ marginLeft: '10px' }}
                                                onClick={() =>
                                                    handleAddNewSelectData(
                                                        data.line_process,
                                                        'line_process',
                                                    )
                                                }
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    }
                                >
                                    {selectData.line_process && (
                                        selectData.line_process.map((dept) => {
                                            return (
                                                <Option
                                                    value={dept.value}
                                                    key={dept.value}
                                                >
                                                    {dept.label}
                                                </Option>
                                            );
                                        })
                                    )}
                                </Select>
                                {errors && errors.name && (
                                    <span className="error-text">
                                        {errors.name[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                <label className="form-label">
                                    Description
                                </label>
                                <Input.TextArea
                                    rows={6}
                                    value={data.description}
                                    className={
                                        errors && errors.description
                                            ? 'is-error'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        handleChange(
                                            'description',
                                            e.target.value,
                                        )
                                    }
                                />
                                {errors && errors.description && (
                                    <span className="error-text">
                                        {errors.description[0]}
                                    </span>
                                )}
                            </div>

                            <div className="form-group">
                                <Button
                                    type="primary"
                                    onClick={handleSave}
                                    loading={loading}
                                    style={{
                                        backgroundColor: '#1b2086',
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    Submit
                                </Button>

                                <Button
                                    htmlType="reset"
                                    type="primary"
                                    onClick={handleReset}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: '#1b2086',
                                        marginLeft: 10,
                                        borderColor: '#1b2086',
                                    }}
                                >
                                    Reset
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    );
}

export default LineProcessForm;
